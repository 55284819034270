import React from "react";

class Logout extends React.Component {

  componentDidMount(){
    console.log("Logging out");
    this.props.parentCallback()
    window.location = "/login"
    
  }

  render() {
    return (
      <div>
          Logging out
      </div>
    );
  }
}

export default Logout;