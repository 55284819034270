import React, { Component } from 'react';
import './App.css';
import Navbar from './components/Navbar.component.js';
import About from './components/About.component.js';
import Home from './components/Home.component.js';
import Login from './components/Login.component.js';
import Logout from './components/Logout.component.js';
import Register from './components/Register.component.js';
import MainTable from './components/MainTable.component.js';
import Footer from './components/Footer.component.js';
import InvestmentDashboard from './components/InvestmentDashboard.component.js';
import InvestmentEvaluator from './components/InvestmentEvaluator.component.js';
import Marketplace from './components/Marketplace.component.js';
import CreateDeal from './components/CreateDeal.component.js';
import DealFlow from './components/DealFlow.component.js'
import DealTerms from './components/DealTerms.component.js';
import axios from 'axios';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
//import Beta from './components/Beta.component.js';
//import Feedback from "feeder-react-feedback"; // import Feedback component
//import "feeder-react-feedback/dist/feeder-react-feedback.css"; // import stylesheet
import Materialize from 'materialize-css';
import LocationAnalysis from './components/LocationAnalysis.component';
//import Cookies from 'universal-cookie';

//const cookies = new Cookies();
axios.defaults.withCredentials = true;


const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      isLoading: true,
      isLoggedIn: false
    };
  }


  setUserVars = (response_data) => {
    if(response_data){
      localStorage.setItem('user_id', response_data.id);
      localStorage.setItem('email', response_data.email);
      console.log("user ID is: " + localStorage.getItem('user_id'));

      this.setState({
        user_id: response_data.id,
        email: response_data.email
      });

      return true;
    } 
  }

  checkAuth() {
    var user_id = this.state.user_id || localStorage.getItem('user_id');



    return axios.get(API_ENDPOINT + '/api/auth/user_data/' + user_id + '/')
        .then((response) => {
            if (response.data.email) {

              localStorage.setItem('user_id', response.data.id);
              localStorage.setItem('email', response.data.email);

              this.setState({
                user_id: response.data.id,
                email: response.data.email
              });
              return true;
            } else {
              this.logout();
              return false;
            }
        }).catch(error => {
          console.log(error);
          this.logout();
          return false;
        });


  }


  logout = () => {
    sessionStorage.clear();


    localStorage.removeItem('user_id');

    this.setState({user_id: null});

   
    axios.get(API_ENDPOINT + '/api/auth/logout/')
    .catch(error => {
      console.log(error);
      return false;
    });
    

  }


  componentDidMount() {
    Materialize.AutoInit();

    /*
    Skipping doing this for now, as I do not have a serverside token to pass in.
    if(this.state.user_id != null || localStorage.getItem('user_id') != null){
      this.checkAuth()
    }
    */
  }
  

  render() {

    var isLoggedIn = null;
    //const isLoggedIn = this.checkAuth();
    //const user_id = this.state.user_id || cookies.get("user_id"); //cookies.getItem("user_id");
    const user_id = this.state.user_id || localStorage.getItem('user_id');
    //const first_name = 'TBD'; //this.state.user_first_name || cookies.get("user_first_name");
    const email = this.state.email|| localStorage.getItem('email');
    (user_id != null) ? isLoggedIn = true : isLoggedIn = false;
    //console.log("user " + first_name + " with id " + user_id + " is logged in: " + isLoggedIn);
    

 

    return (
      
      <div className="App_container">

      <Navbar isLoggedIn = {isLoggedIn} email = {email} />

      <main>
        <Router>
          <Switch>
            <Route exact path="/about"> 
              <About />
            </Route>
            <Route exact path="/investments"> 
              {isLoggedIn ? <InvestmentDashboard user_id = {user_id} /> : <Redirect to="/login" />}
            </Route>
            <Route exact path="/business-entities">
              {isLoggedIn ? <MainTable /> : <Redirect to="/login" />}
            </Route>
            <Route exact path="/location">
              {isLoggedIn ?  <LocationAnalysis user_id = {user_id} /> : <Redirect to="/" />}
            </Route>
            <Route exact path="/marketplace">
              {isLoggedIn ?  <Marketplace user_id = {user_id} /> : <Redirect to="/" />}
            </Route>
            <Route exact path="/login">
              {!isLoggedIn ? <Login parentCallback = {this.setUserVars} /> : <Redirect to="/" />}
            </Route>
            <Route exact path="/register">
              {!isLoggedIn ? <Register parentCallback = {this.setUserVars} /> : <Redirect to="/" />}
            </Route>
            <Route exact path="/logout">
              {isLoggedIn ?  <Logout parentCallback = {this.logout} /> : <Redirect to="/login" />}
            </Route>
            <Route path="/evaluate/:id?" render={(props) => <InvestmentEvaluator user_id = {user_id} {...props} />} />
            <Route path="/deal/create/:id?" render={(props) => <CreateDeal user_id = {user_id} {...props} />} />  
            <Route path="/deal/:id?" render={(props) => <DealTerms user_id = {user_id} {...props} />} />  

            <Route exact path="/dealflow" render={() => <DealFlow />} />

            <Route path="/"> 
              <Home />
            </Route> 
          </Switch>
        </Router>
      </main>
      



      {/* <Beta /> */}
      {/* <Feedback projectId="6135a04fe9549400046cbf1e" /> */}
      
      <Footer />
      </div>
      
    );
  }
}

export default App;
