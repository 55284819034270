import React, { Component } from 'react';
import { PopupButton } from '@typeform/embed-react'

class About extends Component {

  render(){
    return(
      <div>

        <div className="about-horizontal row">

          <div className="col s10 offset-s1 center-align">
              <h4 className="center-align teal-text text-darken-4">
                Simplified real estate investing.       
              </h4>
          </div>

          <div className="col s8 offset-s2">
          {/*
          <p>
            Bitprops is a real estate investment platform centered around the concept of property syndication. Both active deal makers and passive investors can benefit from our tools and marketplace.
            Our mission is to make real estate investing as easy and straightforward as possible for both new and experienced investors. 
            We aim to derisk the process of investing by providing rapid screening tools and detailed performance history. 
            We want to increase your access to deals by enabling multiple investors to buy into a property together.
          </p>

          <div className="row">
            <div className="card col s12 l4 offset-l1">
              <div className="card-content">
                <span className="card-title blue-grey-text text-darken-3">Active Investors</span>
                <p>
                Our investment evaluator helps you identify properties with good returns. If you see something you like, you can sponsor a deal and invite other investors to join your deal.
                </p>
              </div>
            </div>
            <div className="card col s12 l4 offset-l1">
              <div className="card-content">
                <span className="card-title blue-grey-text text-darken-3">Passive Investors</span>
                <p>
                Join deals you are invited to or browse our marketplace for allocation in existing deals. Enjoy the benefits of buying into leveraged, tax-advantaged cash flow with minimal hassle.
                </p>
              </div>
            </div>
          </div>
          */}

          <p>
            Bitprops is a real estate investment platform with a mission to make real estate investing as easy and straightforward as possible. 
            We aim to derisk the process of investing by providing rapid screening tools, detailed performance history, and aggregated location information.
            <br /><br />
            We're not trying to create the next gen anything. 
            We're not into fancy buzz words and industry jargon. 
            We simply take old school investing concepts, and put them into language and tools that make sense.
            Our goal is to give you everything you need and nothing you don't.
          </p>

        </div>

        <div className="col s10 offset-s1 center-align">
          <a href="/evaluate" className="waves-effect waves-light btn-large center cyan darken-4">Try it now</a>
        </div>



        </div>


        <div className="about-horizontal teal lighten-5">
          <div className="container">
          <h5 className="center">Help us make Bitprops better by answering a few questions</h5>
          <br />
            <div className="center">
              <PopupButton className="waves-effect waves-light btn cyan darken-4" id="FnIwtxlt" style={{ fontSize: 15 }} >
                Take the survey (2 min)
              </PopupButton>
            </div>
          </div>
        </div>

        
      </div>  
    );
  }
  
}

export default About;