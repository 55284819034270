import React, { Component } from 'react';
import axios from 'axios';


class CreateDeal extends Component {
  constructor(props){
    super(props);
    this.publishDeal = this.publishDeal.bind(this);

    this.state = {
        investment_entities: [],
        users: [],
        isLoading: false,
        error: null,
      };


    this.return_minimum = React.createRef();
    this.sponsor_upside_cash_flow = React.createRef();
    this.sponsor_upside_equity = React.createRef();
    this.reserve_fund_limit = React.createRef();
    this.reserve_fund_rate = React.createRef();

  }

  getInvestmentEntity(property_id) {

    var user_id = this.props.user_id;

    console.log("the user ID is: " + user_id)

    if(user_id != null){
      
      axios.get(process.env.REACT_APP_API_ENDPOINT + '/api/investment_entity/' + property_id)
      .then(result => 
        {
          console.log(result.data);
        });
      
      

      axios.get(process.env.REACT_APP_API_ENDPOINT + '/api/investment_entity/' + property_id)
        .then(result =>  
          this.setState({
            investment_name: result.data.name,
            address: result.data.address,
            property_type: result.data.property_type,
            purchase_price: result.data.purchase_price,
            down_payment_percentage: result.data.down_payment_percentage,
            closing_costs: result.data.closing_costs,
            cash_at_close: result.data.cash_at_close,
            interest_rate: result.data.interest_rate,
            building_value: result.data.building_value,
            square_footage: result.data.square_footage,
            price_per_square_foot: result.data.price_per_square_foot,
            monthly_rent: result.data.monthly_rent,
            monthly_debt_service: result.data.monthly_debt_service,
            landlord_expenses: result.data.landlord_expenses,
            insurance: result.data.insurance,
            property_taxes: result.data.property_taxes,
            misc_fees: result.data.misc_fees,
            management_fees: result.data.management_fees,
            graph_data: JSON.parse(result.data.graph_data),
            net_cash: result.data.net_cash,
            cash_yield: result.data.cash_yield,
            equity_yield: result.data.equity_yield,
            deal_status: result.data.deal_status,
            isLoading: false,
          })
        ).then( () => {
        /*
          this.setInterestRate()
          this.checkPropertyTypeRadioButton()
          this.generateAmortizationSchedule()
          */
        }).catch(error => this.setState({
          error,
          isLoading: false
        }, ()=> {
          window.location = "/evaluate";
        }));

    } else {
      window.alert("You must log in before retrieving an investment entity");
    }

  }

  publishDeal() {

    var user_id = this.props.user_id;

    console.log("the user ID when I try to publish is: " + user_id)

    var property_id = this.props.match.params.id;


    var return_minimum = this.return_minimum.current.value;
    var sponsor_upside_cash_flow = this.sponsor_upside_cash_flow.current.value;
    var sponsor_upside_equity = this.sponsor_upside_equity.current.value;
    var reserve_fund_limit = this.reserve_fund_limit.current.value;
    var reserve_fund_rate = this.reserve_fund_rate.current.value;

    if(user_id != null){
      axios.post(process.env.REACT_APP_API_ENDPOINT + '/api/deal/', {
        return_minimum: return_minimum,
        sponsor_upside_cash_flow: sponsor_upside_cash_flow,
        sponsor_upside_equity: sponsor_upside_equity,
        reserve_fund_limit: reserve_fund_limit,
        reserve_fund_rate: reserve_fund_rate,
        investment_entity_id: property_id,
        user_id: user_id,

      }).then(resp => {
        console.log(resp.data);
        window.location = "/marketplace";
      }).catch(error => {
          console.log(error);
      });
    } else {
      window.alert("You must log in before saving a deal");
    }
  }


      
  componentDidMount() {

    if(this.props.match.params.id){
        sessionStorage.clear();
        this.getInvestmentEntity(this.props.match.params.id)
      } else{
  
        this.setState({
          isLoading: false,
        }, () => {
        /*
          this.checkPropertyTypeRadioButton()
          this.generateAmortizationSchedule()
        */
        })
  
      }

  }
  

  
  
  render(){

    return(

  
      <div className="container">

            <div className="section">
                <h5 className="deep-purple-text text-darken-4">Property details</h5>
                <div><span className="text-bold">Address:</span> {this.state.address}</div>
                <div><span className="text-bold">Property type:</span> {this.state.property_type}</div>     
                <div><span className="text-bold">Purchase price:</span> ${parseInt(this.state.purchase_price).toLocaleString()}</div>
                <div><span className="text-bold">Estimated value:</span> $[zestimate or equivalent]</div>
                <div><span className="text-bold">Square footage:</span> {(this.state.square_footage || 0).toLocaleString()}</div>
                <div><span className="text-bold">Building value:</span> ${parseInt(this.state.building_value).toLocaleString()}</div>
            </div>

            <div className="section">
                <h5 className="deep-purple-text text-darken-4">Loan details</h5>
                <div><span className="text-bold">Loan amount:</span> ${parseInt(this.state.purchase_price * (1-this.state.down_payment_percentage)).toLocaleString()}</div>
                <div><span className="text-bold">Loan type:</span> 30-year fixed rate</div>
                <div><span className="text-bold">Interest rate:</span> {(this.state.interest_rate || 0).toLocaleString(undefined,{style: 'percent', maximumFractionDigits: 3})}</div>
                <div><span className="text-bold">Monthly debt-service:</span> ${parseInt(this.state.monthly_debt_service).toLocaleString()} </div>
            </div>

            Closing costs: {(this.state.cash_at_close || 0).toLocaleString()}



                    <div className="create-deal-form-content z-depth-2 col s12 m4 offset-m1">

                        <div className="form">
                        <div className="form-group">
                            <label htmlFor="threshold">Minimum Return Threshold</label>
                            <input type="number" ref={this.return_minimum} name="threshold" placeholder="" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Sponsor share of equity upside</label>
                            <input type="number" ref={this.sponsor_upside_equity} name="sponsor_equity" placeholder="5%" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Sponsor share of cash flow</label>
                            <input type="number" ref={this.sponsor_upside_cash_flow} name="sponsor_cash_flow" placeholder="5%" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Reserve fund cap</label>
                            <input type="text" ref={this.reserve_fund_limit} name="reserve_fund_cap" placeholder="$1,000,000" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Reserve rate</label>
                            <input type="text" ref={this.reserve_fund_rate} name="reserve_rate" placeholder="10%" />
                        </div>
                        </div>

                        <div className="login-footer">
                          <span className="text-bold">Note:</span> as Sponsor for this deal, you commit to funding at least 10% of the cash required to close -or- $50,000.
                          <div>
                              <button type="button" className="btn" onClick={this.publishDeal}>
                              Publish deal
                              </button>
                          </div> 
                        </div>
                    </div>
      </div>



    );
  }
  
}

export default CreateDeal;