import React, { Component } from 'react';
import axios from 'axios';

class MainTable extends Component {
  constructor(props){
    super(props);
    this.state = {
        user_id: 1, //This ID should be assigned based on whoever is logged in
        business_entities: [],
        users: [],
        isLoading: false,
        error: null,
      };
  }


  getBusinessEntities() {
    
    this.setState({ isLoading: true });
    axios.get(process.env.REACT_APP_API_ENDPOINT + '/business_entities')
      .then(result => this.setState({
        business_entities: result.data,
        isLoading: false
      }))
      .catch(error => this.setState({
        error,
        isLoading: false
      }));
      
  /*
     fetch('http://localhost:4000/business_entities')
      .then(response => response.json())
      .then(data => this.setState({
        business_entities: data,
        thisSucks: "false"
      }), () => {
        console.log("hello");
      });
      */
  }
  
    getUsers() {
      this.setState({ isLoading: true });
      axios.get(process.env.REACT_APP_API_ENDPOINT + '/users')
        .then(result => this.setState({
          users: result.data,
          isLoading: false
        }))
        .catch(error => this.setState({
          error,
          isLoading: false
        }));   
  }
  
  
  componentDidMount() {
    this.getBusinessEntities();
    this.getUsers();
  }
  

  render(){
    
    return(

      <div className="container">        
        <div className="row">
          <div className="col s6"><span className="grey-text text-darken-3 text-bold">Entity Name</span></div>
          <div className="col s4"><span className="grey-text text-darken-3 text-bold">State of Incorporation</span></div>
          <div className="col s2"><span className="grey-text text-darken-3 text-bold">Equity</span></div>
        </div>
        <ul className="collapsible">
          {this.state.business_entities.map(entity =>
                                            
            <li key={entity.id}>
              <div className="row collapsible-header">
                <div className="col s6">{entity.name}</div>
                <div className="col s4">{entity.state}</div>
                <div className="col s2">%</div>
              </div>
              <div className="collapsible-body">
                  <div className="section">
                    <h5 className="deep-purple-text text-darken-4">Member Information</h5>
                    <div className="row">                    
                      <div className="col s2 text-bold">Name</div>
                      <div className="col s2 text-bold">Type</div>
                      <div className="col s2 text-bold">Equity</div>
                      <div className="col s2 text-bold">Profit and Loss Allocation</div>
                      <div className="col s2 text-bold">Capital Contributions</div>
                      <div className="col s2 text-bold">Address</div>
                    </div>
                    <ul>
                      {entity.members.map(member =>

                        <li key={member.id}>
                          <div className="row">                    
                            <div className="col s2">{member.id}</div>
                            <div className="col s2">{member.type}</div>
                            <div className="col s2">{member.equity}%</div>
                            <div className="col s2">{member.pl_alloc}%</div>
                            <div className="col s2">${member.capital_contributions}</div>
                            <div className="col s2">{member.address}</div>
                          </div>
                        </li>
                        
                      )}
                      
                    </ul>
                  </div>
                  <div className="divider"></div>
                  <div className="section">
                    <h5 className="deep-purple-text text-darken-4">Entity Information</h5>
                    <div className="row text-bold"><div className="col s4">Business Address</div></div>
                    <div className="row"><div className="col s4">{entity.address}</div></div>
                    <div className="row text-bold"><div className="col s4">Date of Incorporation</div></div>
                    <div className="row"><div className="col s4">{entity.incorporation_date}</div></div>
                    <div className="row text-bold"><div className="col s4">Company Number</div></div>
                    <div className="row"><div className="col s4">{entity.company_number}</div></div>
                    <div className="row text-bold"><div className="col s6">Employer Identifcation Number (EIN) / Tax ID</div></div>
                    <div className="row"><div className="col s4">{entity.ein}</div></div>
                    <div className="row text-bold">
                      <div className="col s4">
                        Implied Valuation
                        <i className="material-icons tooltipped" data-position="right" data-tooltip="Implied valuation is derived from capital contributions in exchange for equity. Implied contributions do not reflect intrinsic value of the business.">info_outline</i>
                      </div>
                    </div>
                    <div className="row"><div className="col s4">${entity.implied_valuation}</div></div>
                  </div>
                  <div className="divider"></div>
                  <div className="section">
                    <h5 className="deep-purple-text text-darken-4">Electronic Documents</h5>
                    <p>
                      <div className="row"><div className="col s6"><a href={entity.articles_of_incorporation}>Articles of Incorporation</a></div></div>
                      <div className="row"><div className="col s6"><a href={entity.operating_agreement}>Operating Agreement</a></div></div>
                    </p>
                  </div>
              </div>
            </li>
            
          )}
          
        </ul>
      </div>
    );
  }
  
}

export default MainTable;