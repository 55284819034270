import React, { Component } from 'react';

class Navbar extends Component {
  constructor(props){
    super(props);
    this.changeActiveLink = this.changeActiveLink.bind(this);
    
    var email = this.props.email;

    if(email){
      email = email.split("@")[0]
    }

    this.state = {
      email: email,
      data: null
    };
  }

  changeActiveLink(e) {
    console.log(e.target.className);
    e.target.className = "active"; 
  }

  
  render(){

    return(
        <nav className="cyan lighten-5">
          <div className="nav-wrapper container">
          <a href="/home"><img className="nav-logo" alt="bitprops_logo" src="/bitprops_logo128.png" /></a><a href="/home" className="brand-logo blue-grey-text text-darken-3">bitprops</a>
            {this.props.isLoggedIn
            ?
            <span>
              <a href="_blank" data-target="mobile-demo" className="sidenav-trigger right blue-grey-text text-darken-3"><i className="material-icons">menu</i></a>
              <ul id="nav-mobile" className="right hide-on-med-and-down" >
                <li><a href="/about">About</a></li>
                <li><a href="/evaluate">Investment Evaluator</a></li>
                <li><a href="/investments">Investment Dashboard</a></li>
                {/*
                <li><a href="/marketplace">Marketplace</a></li>
                */}
                <li><a href="/location">Location Analysis</a></li>
                <li>
                <a className='dropdown-trigger blue-grey-text text-darken-3' href='_blank' data-target='profile_dropdown'>Welcome, {this.state.email}<i className="material-icons right profile_dropdown_icon">arrow_drop_down</i></a>
                  <ul id='profile_dropdown' className='dropdown-content'>
                    <li><a href="/logout">Logout</a></li>
                  </ul>
                </li>
              </ul>

              <ul className="sidenav" id="mobile-demo">
                <li><a href="/about">About</a></li>
                <li><a href="/evaluate">Investment Evaluator</a></li>
                <li><a href="/investments">Investment Dashboard</a></li>
                {/*
                <li><a href="/marketplace">Marketplace</a></li>
                */}
                <li><a href="/location">Location Analysis</a></li>
                <li><a href="/logout">Logout</a></li>
              </ul>
            </span>
            :
            <span>
              <a href="_blank" data-target="mobile-demo" className="sidenav-trigger right blue-grey-text text-darken-3"><i className="material-icons">menu</i></a>
              <ul id="nav-mobile" className="right hide-on-med-and-down" >
                <li><a href="/about">About</a></li>
                <li onClick={this.changeActiveLink}><a href="/evaluate">Investment Evaluator</a></li>
                <li><a href="/login">Login / Register</a></li>
              </ul>

              <ul className="sidenav" id="mobile-demo">
                <li><a href="/about">About</a></li>
                <li onClick={this.changeActiveLink}><a href="/evaluate">Investment Evaluator</a></li>
                <li><a href="/login">Login / Register</a></li>
              </ul>

            </span>
            
            }

          </div>
    
        </nav>  
    );
  }
  
}

export default Navbar;