import React, { Component } from 'react';
import Script from 'react-load-script';


class AddressAutocomplete extends Component {
  // Define Constructor
  constructor(props) {
    super(props);

    // Declare State
    this.state = {
      city: '',
      query: ''
    };

  }

  handleScriptLoad = () => {
    // Declare Options For Autocomplete
    const options = {
      componentRestrictions: { country: "us" },
      fields: ["address_components", "formatted_address", "geometry"],
      types: ["address"],
    };

    // Initialize Google Autocomplete
    /*global google*/ // To disable any eslint 'google not defined' errors
    this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById('address'),
      options,
    );

    // Avoid paying for data that you don't need by restricting the set of
    // place fields that are returned to just the address components and formatted
    // address.
    this.autocomplete.setFields(['address_components', 'formatted_address']);

    // Fire Event when a suggested name is selected
    this.autocomplete.addListener('place_changed', this.handlePlaceSelect);
  }
  
  handlePlaceSelect = () => {

    
    const addressObject = this.autocomplete.getPlace();
    const address = addressObject.address_components;
    
    console.log(JSON.stringify(address))

    // Extract State From Address Object
    const address_state = address.filter(f => JSON.stringify(f.types) === JSON.stringify(['administrative_area_level_1', 'political']))[0].short_name

    var address_city = '';

    // Extract City from Address Object
    try{
      address_city = address.filter(f => JSON.stringify(f.types) === JSON.stringify(['locality', 'political']))[0].short_name
    } catch (e) {
      console.log("locality does not exist for this address. Checking for sublocality.")
      try{
        address_city = address.filter(f => JSON.stringify(f.types) === JSON.stringify(['sublocality_level_1', 'sublocality', 'political']))[0].short_name
      } catch (e) {
        console.log("sublocality does not exist for this address.")
      }
    }


    
    const lat = addressObject.geometry.location.lat()
    const lng = addressObject.geometry.location.lng()

    // Check if address is valid
    if (address) {
      // Set State
      //I am commenting this out because I have no idea what purpose it serves
      /*
      this.setState(
        {
          city: address[0].long_name,
          query: addressObject.formatted_address,
        }
      );
      */

      this.props.parentCallback(addressObject.formatted_address, address_state, address_city, lat, lng);
    }
  }

  render() {
    return (
      <div className="input-field">
        <Script 
            url="https://maps.googleapis.com/maps/api/js?key=AIzaSyDtm3xMo-6G-auNj6GNbdlSnmgfPkh8VYs&libraries=places"          
            onLoad={this.handleScriptLoad}        
        />   
        <label htmlFor="address" className="active">Address</label>  
        <input id="address" className="validate" defaultValue={this.props.defaultValue} placeholder="Enter address here" type="search" autoComplete="off" min="0" max="100"/>

      </div>
    );
  }
}

export default AddressAutocomplete;