import React, { Component } from 'react';
import axios from 'axios';


class DealTerms extends Component {
  constructor(props){
    super(props);
    this.state = {
        investment_entities: [],
        users: [],
        isLoading: false,
        error: null,
      };
  }

  calibrateToInvestment(){
    console.log("calibrating based on investment amount");

    var price_per_investor_share = 1;
    var investment_amount = sessionStorage.getItem('investment_amount') || 1000;
    var dividend = 0;
    var address = this.state.address.split(',');
    var street = address[0].trim();
    var city = address[1].trim();
    var state = address[2].split(' ')[1];

    //TODO: update this to a more comprehensive and sophisticated return calculation
    var annual_return = this.state.cash_yield + this.state.equity_yield;


    var num_shares = investment_amount / price_per_investor_share;
    console.log("num_shares:" + num_shares);
    var investment_equity = num_shares / this.state.total_shares;

    dividend = (this.state.net_cash / 12) * investment_equity;
    console.log("equity: " + investment_equity);
    console.log("dividend: " + dividend);

    var percent_committed = this.state.investor_shares_committed / this.state.investor_shares;

    this.setState({
        dividend: dividend,
        street: street,
        city: city,
        state: state,
        investment_equity: investment_equity,
        annual_return: annual_return,
        percent_committed: percent_committed,
        investment_amount: investment_amount
    });


  }


  getInvestmentEntity(investment_entity_id) {

    if(investment_entity_id != null){

        console.log(investment_entity_id);
      
      axios.get(process.env.REACT_APP_API_ENDPOINT + '/api/investment_entity/' + investment_entity_id)
      .then(result => 
        {
          console.log(result.data);
        });


        console.log("gets here");
      
      

      axios.get(process.env.REACT_APP_API_ENDPOINT + '/api/investment_entity/' + investment_entity_id)
        .then(result =>  
          this.setState({
            investment_name: result.data.name,
            address: result.data.address,
            property_type: result.data.property_type,
            purchase_price: result.data.purchase_price,
            down_payment_percentage: result.data.down_payment_percentage,
            closing_costs: result.data.closing_costs,
            interest_rate: result.data.interest_rate,
            building_value: result.data.building_value,
            square_footage: result.data.square_footage,
            price_per_square_foot: result.data.price_per_square_foot,
            monthly_rent: result.data.monthly_rent,
            landlord_expenses: result.data.landlord_expenses,
            insurance: result.data.insurance,
            property_taxes: result.data.property_taxes,
            misc_fees: result.data.misc_fees,
            management_fees: result.data.management_fees,
            graph_data: JSON.parse(result.data.graph_data),
            net_cash: result.data.net_cash,
            cash_yield: result.data.cash_yield,
            equity_yield: result.data.equity_yield,
            total_shares: result.data.total_shares,
            sponsor_shares: result.data.sponsor_shares,
            investor_shares: result.data.investor_shares,
            investor_shares_committed: result.data.investor_shares_committed,
            deal_status: result.data.deal_status,
            isLoading: false,
          })
        ).then( () => {
            this.calibrateToInvestment();
        }).catch(error => this.setState({
          error,
          isLoading: false
        }, ()=> {
          //window.location = "/evaluate";
        }));

    } else {
      window.alert("You must log in before retrieving an investment entity");
    }

  }


      
  componentDidMount() {
    var investment_entity_id = this.props.match.params.id;
    this.getInvestmentEntity(investment_entity_id);
  }
  

  
  
  render(){

    return(

  
      <div className="container"> 

                <div className="row deal-icons">    
                    <button className="transparent-background rounded clickable"><i className="material-icons">remove_red_eye</i></button>
                    <button className="transparent-background rounded clickable"><i className="material-icons">share</i></button>
                    <button  className="transparent-background rounded clickable"><i className="material-icons">favorite_border</i></button>
                </div>
                <div className="deal-header row">
                    <div className="deal-header-content">
                        <span className="white-text">Buy </span>
                        <span className="white-text text-bold">{(this.state.investment_equity|| 0).toLocaleString(undefined,{style: 'percent', maximumFractionDigits: 2})} </span>
                        <span className="white-text">of </span> 
                        <a className="white-text" href="_blank">{this.state.street}</a>
                        <span className="white-text"> for </span> 
                        <span className="investment-price white-text">$ {(this.state.investment_amount || 0).toLocaleString()} </span>

                        <button className="btn">Invest</button>
                    </div>

                </div>

                <div className="deal-highlights row">
                    <div className="col s3">

                    {(this.state.percent_committed || 0).toLocaleString(undefined,{style: 'percent', maximumFractionDigits: 2})} committed
                        <div className="progress">
                                <div className="determinate" style={{width: (this.state.percent_committed || 0).toLocaleString(undefined,{style: 'percent', maximumFractionDigits: 2})}}></div>
                        </div>
                    </div>
                    <div className="col s3">
                        <img className="highlight-image hide-on-small-only left" alt="dividend icon" src="/icons/dividend_icon.png" />
                        <span className="highlights-text teal-text text-darken-4 left">Dividend</span><br />
                        <span className="highlight teal-text text-darken-4">${(this.state.dividend || 0).toFixed(2)} /</span> <span className="highlights-text teal-text text-darken-4"> Month</span>
                    </div>
                    <div className="col s3">
                        <img className="highlight-image hide-on-small-only left" alt="cash yield icon" src="/icons/cash_yield_icon.png" />
                        <span className="highlights-text teal-text text-darken-4 left">Cash Yield </span><br />
                        <span className="highlight teal-text text-darken-4">{(this.state.cash_yield || 0).toLocaleString(undefined,{style: 'percent', maximumFractionDigits: 2})}</span>
                    </div>
                    <div className="col s3">
                        <img className="highlight-image hide-on-small-only left" alt="IRR icon" src="/icons/irr_icon.png" />
                        <span className="highlights-text teal-text text-darken-4 left">IRR</span><br />
                        <span className="highlight teal-text text-darken-4">{(this.state.annual_return || 0).toLocaleString(undefined,{style: 'percent', maximumFractionDigits: 2})}</span>
                    </div>
                </div>

                <div class="row">

                    <ul class="tabs">
                        <li class="tab col s2"><a className="active" href="#overview_tab">Overview</a></li>
                        <li class="tab col s2"><a href="#test2">Property Details</a></li>
                        <li class="tab col s2"><a href="#test3">Financial Details</a></li>
                        <li class="tab col s2"><a href="#test4">Sponsor</a></li>
                        <li class="tab col s2"><a href="#test5">Strategy</a></li>
                        <li class="tab col s2"><a href="#test6">Documents</a></li>
                    </ul>

                    <div id="overview_tab" class="col s12">
                        <div className="row">
                            <div className="col s6">
                                <div class="carousel">
                                    <a class="carousel-item" href="#one!"><img alt="placeholder" src="https://lorempixel.com/250/250/nature/1"/></a>
                                    <a class="carousel-item" href="#two!"><img alt="placeholder" src="https://lorempixel.com/250/250/nature/2"/></a>
                                    <a class="carousel-item" href="#3!"><img alt="placeholder" src="https://lorempixel.com/250/250/nature/3"/></a>
                                    <a class="carousel-item" href="#4!"><img alt="placeholder" src="https://lorempixel.com/250/250/nature/4"/></a>
                                    <a class="carousel-item" href="#5!"><img alt="placeholder" src="https://lorempixel.com/250/250/nature/5"/></a>
                                </div>
                            </div>

                            <div className="property-summary col s6">
                                <div class="section">
                                    <h5>Property Summary</h5>
                                </div>
                                <div className="row">
                                    <div className="col s7">Property Type</div>
                                    <div className="col s5">{this.state.property_type}</div>
                                </div>
                                <div class="divider"></div>

                                <div className="row">
                                    <div className="col s7">Property Class</div>
                                    <div className="col s5">Class A</div>
                                </div>
                                <div class="divider"></div>

                                <div className="row">
                                    <div className="col s7">Address</div>
                                    <div className="col s5">{this.state.address}</div>
                                </div>
                                <div class="divider"></div>
                                <div className="row">
                                    <div className="col s7">Property Purchase Price</div>
                                    <div className="col s5">${(this.state.purchase_price || 0).toLocaleString()}</div>
                                </div>
                                <div class="divider"></div>


                            </div>

                        </div>
                    </div>
                    <div id="test2" class="col s12">Test 2</div>
                    <div id="test3" class="col s12">Test 3</div>
                    <div id="test4" class="col s12">Test 4</div>
                    <div id="test5" class="col s12">Test 5</div>
                    <div id="test6" class="col s12">Test 6</div>
                </div>
      </div>



    );
  }
  
}

export default DealTerms;