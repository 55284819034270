import React, { Component } from 'react';

class Footer extends Component {

  render(){
    return(




    <footer className="page-footer blue-grey lighten-5">
        <div className="container">
          <div className="row">
            <div className="col l9 offset-l1 s12 footer-terms">
                <span className="blue-grey-text text-darken-1 text-bold">
                    Use of Bitprops and its evaluation tools signifies your agreement to our Terms of Use and the terms posted below.
                </span>
                <p className="blue-grey-text text-darken-1">
                    The calculators, evaluators, and information found on Bitprops are designed to be used for informational and educational purposes only, and do not constitute investment advice. 
                    Bitprops recommends that you seek the advice of a real estate professional before making any type of investment. 
                    The results presented are estimates that rely on many assumptions, and may not reflect the actual costs or return of your own investments. 
                    Bitprops is not responsible for the consequences of any decisions or actions taken in reliance upon or as a result of the information provided by Bitprops and its tools. 
                    Furthermore, Bitprops is not responsible for any human or mechanical errors or omissions. 
                    Bitprops obtains property details from various third-party sources, and Bitprops is not responsible or liable for the accuracy, completeness, or suitability of the property details. 
                    You are responsible for confirming the property details are accurate, complete, and suitable for your needs.
                </p>
            </div>
          </div>
        </div>
        <div className="footer-copyright blue-grey darken-4">
          <div className="container">
          © 2024 Bitprops all rights reserved
          <a className="grey-text text-lighten-4 right" href="#!">More Links</a>
          </div>
        </div>
    </footer>


);
}

}

export default Footer;