import React from "react";
import axios from 'axios';
import sha256 from 'crypto-js/sha256';

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.registerUser = this.registerUser.bind(this);
    this.email = React.createRef();
    this.password = React.createRef();
  }

  createToken(email, password) {
    var token = String(sha256(email + password));
    return token;
  }

  registerUser() {
      var email = this.email.current.value;
      var password = this.password.current.value;

    axios.post(process.env.REACT_APP_API_ENDPOINT + '/api/auth/signup', {
      email: email,
      password: password,
    }).then(resp => {
      console.log(resp.data);
      var created = resp.data[1];
      if(created){
        this.props.parentCallback(resp.data[0]);
        window.location = "/evaluate"
        
      } else {
        window.alert("An account with this email already exists");
      }
    }).catch(error => {
      console.log(error);
    });
    
  }

  render() {
    return (
      <div className="container" ref={this.props.containerRef}>

        <div className="row">

          <div className="login-display col m7 hide-on-small-only">
            <img className="login-image" alt="login" src="/bitprops_logo_large.png" />
            <span className="blue-text text-darken-3">bit</span><span className="blue-grey-text text-darken-4">props</span>
            <div>Simplified real estate investing.</div>
          </div>

          <div className="login-form-content z-depth-2 col s12 m4 offset-m1">

            <form className="form">
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input type="text" ref={this.email} name="email" placeholder="email" />
              </div>
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <input type="password" ref={this.password} name="password" placeholder="password" />
              </div>
            </form>
 
            <div className="login-footer">
              <button type="button" className="btn login-button" onClick={this.registerUser}>
                Register
              </button>
              <div className="center">Already have an account? <br /><a href="/login">Login</a></div>
            </div>

          </div>
        </div>
      </div>
    );
  }
}

export default Register;