import React, { Component } from 'react';
import DealCard from './DealCard.component';
import axios from 'axios';


class Marketplace extends Component {
  constructor(props){
    super(props);
    this.handleInvestmentAmountChange = this.handleInvestmentAmountChange.bind(this);
    this.state = {
        investment_amount: sessionStorage.getItem('investment_amount') || 1000,
        investment_entities: [],
        users: [],
        isLoading: false,
        error: null,
      };
  }


  handleInvestmentAmountChange(e){
    this.setState({ investment_amount: parseInt(e.target.value) });
    sessionStorage.setItem('investment_amount', parseInt(e.target.value));
  }


  getPublishedDeals() {
    this.setState({ isLoading: true });

    axios.get(process.env.REACT_APP_API_ENDPOINT + '/api/investment_entity/published')
      .then(result => this.setState({
        investment_entities: result.data,
        isLoading: false
      }))
      .catch(error => this.setState({
        error,
        isLoading: false
      }));
  }
      
  componentDidMount() {
    this.getPublishedDeals();
  }
  

  
  
  render(){

    return(

  
      <div className="container"> 

        <div className="form">
          <div className="form-group">
              <label htmlFor="investment_amount" >How much are you looking to invest? ($)</label>
              <input type="number" name="investment_amount" placeholder={sessionStorage.getItem('investment_amount') || 1000} onChange={this.handleInvestmentAmountChange}/>
          </div>
        </div>

        <div className="row">

        {this.state.investment_entities.map(investment_entity =>

                    <div key={investment_entity.id} className="col s12 m4">
                      <DealCard investment_entity_id = {investment_entity.id} investment_amount = {this.state.investment_amount} key = {this.state.investment_amount} />
                    </div>
        )}


        </div>

      </div>



    );
  }
  
}

export default Marketplace;