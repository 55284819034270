import React, { Component } from 'react';
import { PopupButton } from '@typeform/embed-react'

class Home extends Component {
  render(){
    return(
      <div>

        <div className="about-horizontal row">
          {/*
          <div className="col s12 m6 offset-m3 alpha-card">
            <div className="card blue-grey darken-1">
              <div className="card-content white-text">
                <span className="card-title">Please note</span>
                <p>
                  Bitprops is currently in beta. If you encounter issues or have feature requests, please submit feedback by clicking the feedback icon at the bottom right corner of the page.
                </p>
              </div>
            </div>
          </div>
          */}
          <div className="col s10 offset-s1 center-align">
            <h4 className="center-align teal-text text-darken-4">
              Simplified real estate investing.        
            </h4>
            <br />
            <a href="/evaluate" className="waves-effect waves-light btn-large cyan darken-4">Try it now</a>
          </div>
        </div>

        <div className="teal lighten-5 about-horizontal row">
          <div className="col s12 m6 offset-m3">
            <h5 className="center-align blue-grey-text text-darken-3">
              Check out some of our features below
            </h5>
          </div>
        </div>



        <div className="about-horizontal row flush">

          <img className="col s12 m4 offset-m1" src='/gifs/investment_evaluator.gif' alt="loading..." />

          <div className="col s12 m4 offset-m2">
            <div className="card">
              <div className="card-content">
                <span className="card-title blue-grey-text text-darken-3">Quickly screen properties</span>
                <p>
                  Use our <a href="/evaluate">investment evaluator</a> to add details about the cost of buying and owning a property, and the expected return from renting that property and building equity.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="light-blue lighten-5 about-horizontal row flush">
          <div className="col s12 m4 offset-m1">
            <div className="card">
              <div className="card-content">
                <span className="card-title blue-grey-text text-darken-3">Track and organize deals</span>
                <p>
                  Save investments to your <a href="/investments">dashboard</a> to keep track of potential deals and their status.
                </p>
              </div>
            </div>
          </div>

          <img className="col s12 m4 offset-m2" src='/gifs/investment_dashboard.gif' alt="loading..." />

        </div>

        <div className="blue-grey lighten-5 about-horizontal row flush">

          <img className="col s12 m4 offset-m1" src='/gifs/location_details.gif' alt="loading..." />

          <div className="col s12 m4 offset-m2">
            <div className="card">
              <div className="card-content">
                <span className="card-title blue-grey-text text-darken-3">Learn about the area</span>
                <p>
                  Our <a href="/location">location analysis tool</a> provides comprehensive information about the area around a property, enabling you to make informed decisions using more than pure financials. With our tool, you can gain insights on local weather, schools, transportation, and more, helping you invest in a location that fits your broader preferences.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="about-horizontal">
          <div className="container">
          <h5 className="center">Help us make Bitprops better by answering a few questions</h5>
          <br />
            <div className="center">
              <PopupButton className="waves-effect waves-light btn cyan darken-4" id="FnIwtxlt" style={{ fontSize: 15 }} >
                Take the survey (2 min)
              </PopupButton>
            </div>
          </div>
        </div>

        <div className="indigo lighten-5 about-horizontal">

          <h5 className="center">More great things are coming</h5>
          <br />
          <br />
          <div className="row roadmap">
            <div className="card col s10 offset-s1 l4 offset-l1">
              <div className="card-content">
                <span className="card-title blue-grey-text text-darken-3">Generate pro formas</span>
                <p>
                  Project earnings for your investments based on configurable inputs like average rent increases, expected vacancies, and more.
                </p>
              </div>
            </div>

            <div className="card col s10 offset-s1 l4 offset-l2">
              <div className="card-content">
                <span className="card-title blue-grey-text text-darken-3">Download deal info</span>
                <p>
                  Save investment information to the cloud with Google drive, or download raw .xsl files to open in Excel.
                </p>
              </div>
            </div>

            <div className="card col s10 offset-s1 l3 offset-l2 hide">
              <div className="card-content">
                <span className="card-title blue-grey-text text-darken-3">Invite partners</span>
                <p>
                Share investments with friends, family, and other investors to help raise capital and secure commitments for syndications or joint ventures.
                </p>
              </div>
            </div>

            <div className="card col s10 offset-s1 l4 offset-l1">
              <div className="card-content">
                <span className="card-title blue-grey-text text-darken-3">Track performance</span>
                <p>
                Track the performance of your closed deals over time, both in terms of cash generated, as well as the change in equity value of the asset.
                </p>
              </div>
            </div>
            <div className="card col s10 offset-s1 l4 offset-l2">
              <div className="card-content">
                <span className="card-title blue-grey-text text-darken-3">Instant screening</span>
                <p>
                Enter an address, or a URL from popular property-browsing platforms and see AI-powered estimates for income and expenses.
                </p>
              </div>
            </div>
            <div className="card col s10 offset-s1 l4 offset-l1">
              <div className="card-content">
                <span className="card-title blue-grey-text text-darken-3">Growth evaluation</span>
                <p>
                Generate analyses of an area to give insights into future value. Jobs, population growth, %renters vs owners, room for expansion.
                </p>
              </div>
            </div>

            <div className="card col s10 offset-s1 l4 offset-l2">
              <div className="card-content">
                <span className="card-title blue-grey-text text-darken-3">Buy cash flow</span>
                <p>
                Browse the marketplace for blocks of equity in existing, cash flowing deals. Spend your capital with implied leverage to generate cash flow secured by the underlying property.
                </p>
              </div>
            </div>
            <div className="card col s10 offset-s1 l4 offset-l1">
              <div className="card-content">
                <span className="card-title blue-grey-text text-darken-3">Investment liquidity</span>
                <p>
                Sell part or all of your stake in a property to other investors looking to buy cash flow.
                </p>
              </div>
            </div>

            <div className="card col s10 offset-s1 l4 offset-l2">
              <div className="card-content">
                <span className="card-title blue-grey-text text-darken-3">Tax advantages</span>
                <p>
                Unlock the tax advantages of depreciation to bring down your reported income and allow more money to flow through to you.
                </p>
              </div>
            </div>

            <div className="card col s10 offset-s1 l4 offset-l1">
              <div className="card-content">
                <span className="card-title blue-grey-text text-darken-3">Estimate return for dollars-in</span>
                <p>
                See how the value of your property might increase given certain dollars-in actions like replacing a roof or doing a full gut renovation.
                </p>
              </div>
            </div>
            <div className="card col s10 offset-s1 l4 offset-l2">
              <div className="card-content">
                <span className="card-title blue-grey-text text-darken-3">Something else?</span>
                <p>
                Are we missing a killer feature that you'd love to see? Let us know using the feedback form on the bottom right of the page!
                </p>
              </div>
            </div>
          </div>
        </div>

      </div>  
    );
  }
  
}

export default Home;