import React from "react";
import axios from 'axios';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.loginUser = this.loginUser.bind(this);
    this.email = React.createRef();
    this.password = React.createRef();
  }

  loginUser() {
    return axios.post(process.env.REACT_APP_API_ENDPOINT + '/api/auth/login/', {
        email: this.email.current.value,
        password: this.password.current.value,
    }).then(resp => {
        this.props.parentCallback(resp.data)
        window.location = "/evaluate"
      }).catch(error => {
          console.log(error);
      }); 
  }  

  render() {
    return (
      <div className="container" ref={this.props.containerRef}>

        <div className="row">

          <div className="login-display col m7 hide-on-small-only">
            <img className="login-image" alt="login" src="/bitprops_logo_large.png" />
            <span className="blue-text text-darken-3">bit</span><span className="blue-grey-text text-darken-4">props</span>
            <div>Simplified real estate investing.</div>
          </div>

          <div className="login-form-content z-depth-2 col s12 m4 offset-m1">

            <div className="form">
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input type="text" ref={this.email} name="email" placeholder="email" />
              </div>
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <input type="password" ref={this.password} name="password" placeholder="password" />
              </div>
            </div>

            <div className="login-footer">
              <div>
                <button type="button" className="btn login-button" onClick={this.loginUser}>
                  Login
                </button>
              </div>

              <div className="center"><a href="/password-recovery">Forgot password?</a></div>

              <div className="divider"></div>

              <div className="center"><a className="btn orange darken-1" href="/register">Create an account</a></div>
              
            </div>
          </div>

        </div>

      </div>
    );
  }
}

export default Login;