import React, { Component } from 'react';
import AddressAutocomplete from './AddressAutocomplete.component';
import axios from 'axios';

class LocationAnalysis extends Component {
    constructor(props){
        super(props);
        this.handleAddressChange = this.handleAddressChange.bind(this);

        this.state = {
            investment_name: sessionStorage.getItem('investment_name'),
            address: sessionStorage.getItem('address'),
            address_state: '',
            address_city: '',
            latitude: '',
            longitude: '',
            market_score: 0,
            growth_score: 0,
            education_score: 0,
            climate_score: 0,
            landlord_friendliness_score: 0,
            state_property_tax: 0,
            state_sales_tax: 0,
            state_income_tax: 0,
            state_eviction_estimate: '',
            state_eviction_rate: 0,
            safety_score: 0,
            weather_score: 0,
            overall_score: 0,
            walk_score: 0,
            transit_score: 0,
            bike_score: 0,
            ws_link: sessionStorage.getItem('ws_link') || '',
        }
    }

    //Retrieves new location details when address changes
    handleAddressChange(address, address_state, address_city, lat, lng) {
        this.setState({ 
          address: address,
          address_state: address_state,
          address_city: address_city,
          latitude: lat,
          longitude: lng,
        }, async () => {
    
          this.getPropertyDetails();

          await Promise.all([
            this.getWalkScore(),
            this.getGrowthScore(),
          ]);

          //need to fix this. Overall score calculates before the other scores have completed their async process
          //Need to find out how to call this funciton only when all the prior functions have completed.
          //I think I fixed this by wrapping prior functions in an await Promise.all. But I need to test this more thoroughly.
          this.calculateOverallScore()
          
        });  
        sessionStorage.setItem('address', address);  
      }

    getPropertyDetails(){
        console.log("Getting information about the surrounding area")

        var address_state = this.state.address_state;
        var address_city = this.state.address_city;

        console.log("City name is: " + address_city)

        axios.post(process.env.REACT_APP_API_ENDPOINT + '/api/location/state/', {
            address_state: address_state,
        }).then(resp => {
            if (resp.data !== null) { // Check if the response data is not null
                const { 
                  landlord_friendliness, 
                  state_eviction_estimate, 
                  state_eviction_rate, 
                  state_property_tax, 
                  state_sales_tax, 
                  state_income_tax 
                } = resp.data;
                // Only update state for non-null properties
                this.setState(prevState => ({
                  landlord_friendliness_score: landlord_friendliness !== null && landlord_friendliness !== undefined ? landlord_friendliness : prevState.landlord_friendliness_score,
                  state_eviction_estimate: state_eviction_estimate !== null && state_eviction_estimate !== undefined ? state_eviction_estimate : prevState.state_eviction_estimate,
                  state_eviction_rate: state_eviction_rate !== null && state_eviction_rate !== undefined ? state_eviction_rate : prevState.state_eviction_rate,
                  state_property_tax: state_property_tax !== null && state_property_tax !== undefined ? state_property_tax : prevState.state_property_tax,
                  state_sales_tax: state_sales_tax !== null && state_sales_tax !== undefined ? state_sales_tax : prevState.state_sales_tax,
                  state_income_tax: state_income_tax !== null && state_income_tax !== undefined ? state_income_tax : prevState.state_income_tax,
                }));
              }
          }).catch(error => {
              console.log(error);
          });

        axios.post(process.env.REACT_APP_API_ENDPOINT + '/api/location/city/', {
            address_city: address_city,
        }).then(resp => {
            if (resp.data !== null) { // Check if the response data is not null
                const { 
                  safety 
                } = resp.data;
                // Only update state for non-null properties
                this.setState(prevState => ({
                  safety_score: safety !== null && safety !== undefined ? safety : prevState.safety_score,
                }));
              }
          }).catch(error => {
              console.log(error);
          });
    }

    getWalkScore() {

        var address = encodeURIComponent(this.state.address);
        var lat = this.state.latitude;
        var lon = this.state.longitude;

        var walk_score = 0;

        return new Promise((resolve, reject) => {
            axios.post(process.env.REACT_APP_API_ENDPOINT + '/api/location/getWalkScore/', {
                address: address,
                lat: lat,
                lon: lon,
        
            }).then(resp => {

                console.log(resp.data);
                this.setState({
                    walk_score: resp.data.walkscore,
                    transit_score: resp.data.transit.score,
                    bike_score: resp.data.bike.score,
                    ws_link: resp.data.ws_link
                }, () => {
                    walk_score = resp.data.walk_score
                    resolve(walk_score);
                })
            }).then( ()=> {
                    sessionStorage.setItem('ws_link', this.state.ws_link);
            }).catch(error => {
                console.log(error);
            });
        });
    }

    getGrowthScore() {

        var address_state = this.state.address_state;

        var growth_score = 0

        return new Promise((resolve, reject) => {
            axios.post(process.env.REACT_APP_API_ENDPOINT + '/api/location/getGrowthScore/', {
                address_state: address_state,

            }).then(resp => {
                console.log("response data is: ");
                console.log(resp.data)
                this.setState({
                    population_density: resp.data[1][0],
                    population_2021: resp.data[1][1],
                    population_2021_rank: resp.data[1][2],
                    population_2021_change_percentage: resp.data[1][3],
                    population_2021_change_percentage_rank: resp.data[1][4],
                    population_2021_change_number: resp.data[1][5],
                    population_2021_change_numbere_rank: resp.data[1][6]
                })
            }).then( ()=> {
                growth_score = this.calculateGrowthScore()
                resolve(growth_score);
            }).catch(error => {
                console.log(error);
            });
        });
 

    }

    //this is a naive implementation of the growth score calculation for purposes of demonstration
    //a more comprehensive growth score will take additional factors into consideration such as population trends over time
    //Eventually we will use an AI model to predict growth using a variety of factors including job trends, cost of living trends, population age and more
    //These predictions and scores will be a staple of Bitprops and we will need to carefully tune how we determine the values in order to provide the desired user experience.
    calculateGrowthScore() {
        var base = 50
        var multiplier = 3 //chosen somewhat arbitrarily, to multiply against population % changes, so that the overall base score moves a noticeable amount 
        var step_value = multiplier * this.state.population_2021_change_percentage
        var growth_score = parseInt(base + step_value)

        this.setState({
            growth_score: growth_score,
        })

        return growth_score
    }

    //Naive implementation of overall score that takes the average of scores that are returned.
    //If a score for any category is 0 I do not factor into overall score calculation.
    calculateOverallScore() {
        var overall_score = 0
        var running_total = 0
        var considered_vars = 0

        if(this.state.market_score > 0){
            running_total += this.state.market_score
            considered_vars += 1
        }

        if(this.state.growth_score > 0){
            running_total += this.state.growth_score
            considered_vars += 1
        }

        if(this.state.education_score > 0){
            running_total += this.state.education_score
            considered_vars += 1
        }

        if(this.state.climate_score > 0){
            running_total += this.state.climate_score
            considered_vars += 1
        }

        if(this.state.landlord_friendliness_score > 0){
            running_total += this.state.landlord_friendliness_score
            considered_vars += 1
        }

        if(this.state.safety_score > 0){
            running_total += this.state.safety_score
            considered_vars += 1
        }

        if(this.state.weather_score > 0){
            running_total += this.state.weather_score
            considered_vars += 1
        }

        if(this.state.walk_score > 0){
            running_total += this.state.walk_score
            considered_vars += 1
        }

        if(this.state.bike_score > 0){
            running_total += this.state.bike_score
            considered_vars += 1
        }

        if(this.state.transit_score > 0){
            running_total += this.state.transit_score
            considered_vars += 1
        }

        overall_score = running_total / considered_vars

        this.setState({
            overall_score: overall_score,
        })
    }


  render(){
    return(
        <span>

            <div className="container">
    
            <div className="row">

                <div className="col s12 m6">     
                <h5 className="deep-purple-text text-darken-4">Location details</h5>       
                <br />
                    <AddressAutocomplete defaultValue = {this.state.address} parentCallback = {this.handleAddressChange} />              
                </div>
            </div>

            <div className="row">
                <span className="col s12 m6">
                <h6 className="deep-purple-text text-darken-4">Overall score</h6>
                    <div>
                        {this.state.overall_score > 0 ? "Score: " + parseInt(this.state.overall_score) : "Data not available"}
                    </div>
                    <div className="range-field">
                        <input value={this.state.overall_score} readOnly type="range" id="test5"  min="0" max="100" />
                    </div>
                </span>
            </div>

            <div className="row">
                    <span className="col s6 m4">
                    <h6 className="deep-purple-text text-darken-4">Market indicator</h6>
                        <div>
                            {this.state.market_score > 0 ? "Score: " + parseInt(this.state.market_score) : "Data not available"}
                        </div>
                        <div className="range-field">
                            <input value={this.state.market_score} readOnly type="range" id="test5"  min="0" max="100" />
                        </div>
                        {this.state.market_score > 0 ? 
                            <div>
                                Months of inventory: 7 <br />
                                Buyer's Market
                            </div>
                        :
                            <span></span>
                        }
                    </span>



                <span className="col s6 m4 offset-m1">
                <h6 className="deep-purple-text text-darken-4">Growth</h6>
                    <div>
                    {this.state.growth_score > 0 ? "Score: " + parseInt(this.state.growth_score) : "Data not available"}
                    </div>
                    <div className="range-field">
                        <input value={this.state.growth_score} readOnly type="range" id="test5"  min="0" max="100" />
                    </div>
                    <div>       
                        {this.state.population_2021 ?
                            <div>
                                State: {this.state.address_state} <br />
                                5-year population change: Data not available <br />
                                2021 Population: {parseInt(this.state.population_2021).toLocaleString()} <br />
                                1-year population change: {parseInt(this.state.population_2021_change_number).toLocaleString()} <br />
                                Population change percentage: {parseFloat(this.state.population_2021_change_percentage).toFixed(2)}%
                            </div>
                        :
                            <span></span>
                        }

                    </div>
                </span>
            </div>

            <div className="row">
                <span className="col s6 m4">
                <h6 className="deep-purple-text text-darken-4">Education</h6>

                    <div>
                        {this.state.education_score > 0 ? "Score: " + parseInt(this.state.education_score) : "Data not available"}
                    </div>
                    <div className="range-field">
                        <input value={this.state.education_score} readOnly type="range" id="test5"  min="0" max="100" />
                    </div>
                    {this.state.education_score > 0 ?
                        <div>                        
                            Elementary school rating: A+ <br />
                            Middle school rating: C <br />
                            High school rating: B+ <br />
                            Private school tuition: $10,000/year
                        </div>
                    :
                        <span></span>
                    }
                </span>


                <span className="col s6 m4 offset-m1">
                <h6 className="deep-purple-text text-darken-4">Living</h6>
                <div>

                </div>
                <div>          
                    {this.state.walk_score > 0 ?  
                        <span>          
                            Average income tax for {this.state.address_state}: {parseFloat(this.state.state_income_tax).toFixed(2)}% <br />
                            Average sales tax for {this.state.address_state}: {parseFloat(this.state.state_sales_tax).toFixed(2)}% <br /> <br />   
                            <a href="https://www.walkscore.com/how-it-works/" target="How walks core works">Walk Score®: {this.state.walk_score}</a> <br />
                            <a href="https://www.walkscore.com/how-it-works/" target="How walk score works">Transit Score®: {this.state.transit_score}</a> <br />
                            <a href="https://www.walkscore.com/how-it-works/" target="How walk score works">Bike Score: {this.state.bike_score}</a> <br />
                            <br />
                            <a href={this.state.ws_link} target="Walk Score Details">More info from Walk Score</a>
                        </span>
                        :
                        <span>Data not available</span>
                    }
                </div>

                <div>

                    
                </div>
                </span>

            </div>

            <div className="row">

                <span className="col s6 m4">
                <h6 className="deep-purple-text text-darken-4">Weather</h6>
                    <div>
                        {this.state.weather_score > 0 ? "Score: " + parseInt(this.state.weather_score) : "Data not available"}
                    </div>

                    <div className="range-field">
                        <input value={this.state.weather_score} readOnly type="range" id="test5"  min="0" max="100" />
                    </div>

                    {this.state.weather_score > 0 ?
                        <div>
                            Summer high: 95 degrees <br />
                            Summer humidity: 80% <br />
                            Winter low: 10 degrees <br />
                            Days of sunlight: 180
                        </div>
                    :
                        <span></span>
                    }

                </span>


                <span className="col s6 m4 offset-m1">
                <h6 className="deep-purple-text text-darken-4">Climate impact</h6>
                    <div>
                        {this.state.climate_score > 0 ? "Score: " + parseInt(this.state.climate_score) : "Data not available"}
                    </div>

                    <div className="range-field">
                        <input value={this.state.climate_score} readOnly type="range" id="test5"  min="0" max="100" />
                    </div>
                    
                    {this.state.climate_score > 0 ?
                        <div>
                            Elevation: 25 feet <br />
                            Flood risk: Medium <br />
                            5-year drought risk: 48% <br />
                            Natural disasters: earthquakes, hurricanes
                        </div>
                    :
                        <span></span>
                    }
                </span>
            </div>



            <div className="row">
                <span className="col s6 m4">
                <h6 className="deep-purple-text text-darken-4">Landlord friendliness</h6>


                    <div>
                        {this.state.landlord_friendliness_score > 0 ? "Score: " + parseInt(this.state.landlord_friendliness_score) : "Data not available"}
                    </div>

                    <div className="range-field">
                        <input value={this.state.landlord_friendliness_score} readOnly type="range" id="test5"  min="0" max="100" />
                    </div>

                    {this.state.landlord_friendliness_score > 0 ?
                        <div>
                            Average property tax for {this.state.address_state}: {parseFloat(this.state.state_property_tax).toFixed(2)}% <br />
                            Average eviction rate for {this.state.address_state}: {parseFloat(this.state.state_eviction_rate).toFixed(2)}% <br />
                            Estimated eviction timeline for {this.state.address_state}: {this.state.state_eviction_estimate} <br />
                            {
                            //Tenant eviction notice, squatter's right, % evictions
                            }
                        </div>
                    :
                        <span></span>
                    }

                </span>


                <span className="col s6 m4 offset-m1">
                <h6 className="deep-purple-text text-darken-4">Safety</h6>

                    <div>
                        {this.state.safety_score > 0 ? "Score: " + parseInt(this.state.safety_score) : "Data not available"}
                    </div>

                    <div className="range-field">
                        <input value={this.state.safety_score} readOnly type="range" id="test5"  min="0" max="100" />
                    </div>

                    {this.state.safety_score > 0 ?
                        <div>
                            {/*
                            # of crimes: Low <br />
                            Severity of crimes: Low
                            */}
                        </div>
                    :
                        <span></span>
                    }

                </span>
            </div>


                
            
    
            </div>
  
        </span>
    );
  }
  
}

export default LocationAnalysis;