import React, { Component } from 'react';
import axios from 'axios';



class DealCard extends Component {
  constructor(props){
    super(props);
    this.state = {
        investment_entities: [],
        users: [],
        isLoading: false,
        error: null,
      };
  }


  calibrateToInvestment(){
    console.log("calibrating based on investment amount");

    var price_per_investor_share = 1;
    var investment_amount = this.props.investment_amount;
    var dividend = 0;
    var address = this.state.address.split(',');
    var street = address[0].trim();
    var city = address[1].trim();
    var state = address[2].split(' ')[1];

    //TODO: update this to a more comprehensive and sophisticated return calculation
    var annual_return = this.state.cash_yield + this.state.equity_yield;


    var num_shares = investment_amount / price_per_investor_share;
    console.log("num_shares:" + num_shares);
    var investment_equity = num_shares / this.state.total_shares;

    dividend = (this.state.net_cash / 12) * investment_equity;
    console.log("equity: " + investment_equity);
    console.log("dividend: " + dividend);

    var percent_committed = this.state.investor_shares_committed / this.state.investor_shares;

    this.setState({
        dividend: dividend,
        street: street,
        city: city,
        state: state,
        investment_equity: investment_equity,
        annual_return: annual_return,
        percent_committed: percent_committed
    });


  }

  getInvestmentEntity(investment_entity_id) {

    if(investment_entity_id != null){

        console.log(investment_entity_id);
      
      axios.get(process.env.REACT_APP_API_ENDPOINT + '/api/investment_entity/' + investment_entity_id)
      .then(result => 
        {
          console.log(result.data);
        });


        console.log("gets here");
      
      

      axios.get(process.env.REACT_APP_API_ENDPOINT + '/api/investment_entity/' + investment_entity_id)
        .then(result =>  
          this.setState({
            investment_name: result.data.name,
            address: result.data.address,
            property_type: result.data.property_type,
            purchase_price: result.data.purchase_price,
            down_payment_percentage: result.data.down_payment_percentage,
            closing_costs: result.data.closing_costs,
            interest_rate: result.data.interest_rate,
            building_value: result.data.building_value,
            square_footage: result.data.square_footage,
            price_per_square_foot: result.data.price_per_square_foot,
            monthly_rent: result.data.monthly_rent,
            landlord_expenses: result.data.landlord_expenses,
            insurance: result.data.insurance,
            property_taxes: result.data.property_taxes,
            misc_fees: result.data.misc_fees,
            management_fees: result.data.management_fees,
            graph_data: JSON.parse(result.data.graph_data),
            net_cash: result.data.net_cash,
            cash_yield: result.data.cash_yield,
            equity_yield: result.data.equity_yield,
            total_shares: result.data.total_shares,
            sponsor_shares: result.data.sponsor_shares,
            investor_shares: result.data.investor_shares,
            investor_shares_committed: result.data.investor_shares_committed,
            deal_status: result.data.deal_status,
            isLoading: false,
          })
        ).then( () => {
            this.calibrateToInvestment();
        }).catch(error => this.setState({
          error,
          isLoading: false
        }, ()=> {
          //window.location = "/evaluate";
        }));

    } else {
      window.alert("You must log in before retrieving an investment entity");
    }

  }

      
  componentDidMount() {
    this.getInvestmentEntity(this.props.investment_entity_id);

  }
  

  
  
  render(){

    return(

        <div className="card">
            <div className="card-image">
  
                <img alt="property" src="https://materializecss.com/images/sample-1.jpg" />
                
                <span className="card-status transparent-background">Open for investments</span>
                <div className="card-icons">
                    <button className="transparent-background rounded clickable"><i className="material-icons">remove_red_eye</i></button>
                    <button className="transparent-background rounded clickable"><i className="material-icons">share</i></button>
                    <button  className="transparent-background rounded clickable"><i className="material-icons">favorite_border</i></button>
                </div>
                <a href={"/deal/" + this.props.investment_entity_id} className="card-title transparent-background">{(this.state.investment_equity|| 0).toLocaleString(undefined,{style: 'percent', maximumFractionDigits: 2})} of {this.state.street}</a>
            </div>
            <div className="card-content">
                <div className="row">

                    <div className="col s6">
                        <i className="material-icons">home</i><span>{this.state.property_type}</span>
                    </div>
                    <div className="col s6">
                        <i className="material-icons">location_on</i><span>{this.state.city}, {this.state.state}</span>
                    </div>
                    
                </div>

                <div className="row blue-grey lighten-5">
                    <div className="col s7">Investment cost</div>
                    <div className="col s5">${this.props.investment_amount}</div>
                </div>

                <div className="row blue-grey lighten-5">
                    <div className="col s7">Dividend</div>
                    <div className="col s5">${(this.state.dividend || 0).toFixed(2)} monthly</div>
                </div>

                <div className="row blue-grey lighten-5">
                    <div className="col s7 tooltipped" data-position="right" data-tooltip="The percentage of your investment you are targeted to receive in dividend payments throughout the year">Cash on cash return</div>
                    <div className="col s5">{(this.state.cash_yield || 0).toLocaleString(undefined,{style: 'percent', maximumFractionDigits: 2})}</div>
                </div>

                <div className="row blue-grey lighten-5">
                    <div className="col s7">Annual return</div>
                    <div className="col s5">{(this.state.annual_return || 0).toLocaleString(undefined,{style: 'percent', maximumFractionDigits: 2})}</div>
                </div>

                <div className="row">
                        <span>{(this.state.percent_committed || 0).toLocaleString(undefined,{style: 'percent', maximumFractionDigits: 2})} committed</span>
                        <div className="progress">
                            <div className="determinate" style={{width: (this.state.percent_committed || 0).toLocaleString(undefined,{style: 'percent', maximumFractionDigits: 2})}}></div>
                        </div>
                </div>

            </div>
        </div>

    );
  }
  
}

export default DealCard;