import React, { Component } from 'react';
import axios from 'axios';
import { ComposedChart, Bar, Line, Legend, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';


class InvestmentDashboard extends Component {
  constructor(props){
    super(props);
    this.state = {
        investment_entities: [],
        users: [],
        isLoading: false,
        error: null,
      };
  }


  getInvestmentEntities() {
    this.setState({ isLoading: true });
    var user_id = this.props.user_id;

    axios.get(process.env.REACT_APP_API_ENDPOINT + '/api/investment_entity/user/' + user_id)
      .then(result => this.setState({
        investment_entities: result.data,
        isLoading: false
      }))
      .catch(error => this.setState({
        error,
        isLoading: false
      }));
  }

  deleteInvestmentEntity(id) {
    this.setState({ isLoading: true});

    if (window.confirm('Are you sure you want to delete this investment entity?'))
    {
      let res = axios.delete(process.env.REACT_APP_API_ENDPOINT + '/api/investment_entity/' + id + '/')
      console.log(res);
      window.location.reload()
      
    }
    else
    {
       //Do nothing
    }

 
  }

      
  componentDidMount() {
    this.getInvestmentEntities();
  }
  
  calculateNetCash(investment_entity){
    var year_zero_financials = investment_entity.financials[0];

    var annual_rental_income = parseInt(year_zero_financials.monthly_rent) * 12; //only use this if granular tenant information is not available
    console.log(annual_rental_income);
    var annual_expenses = year_zero_financials.landlord_expenses; //TODO: add other expenses
    
    var net_cash = annual_rental_income - annual_expenses;
    return net_cash.toLocaleString();
  }
  
  
  render(){

    return(

  
      <div className="container"> 
      {this.state.investment_entities.length > 0
        ?
        <div className="row">
          <div className="col s5 m2"><span className="grey-text text-darken-3 text-bold">Property</span></div>
          <div className="col s4 m2"><span className="grey-text text-darken-3 text-bold">Purchase price</span></div>
          <div className="col hide-on-small-only m2"><span className="grey-text text-darken-3 text-bold">Price per sqft</span></div>
          <div className="col hide-on-small-only m2"><span className="grey-text text-darken-3 text-bold">Annual net cash</span></div>
          <div className="col hide-on-small-only m1"><span className="grey-text text-darken-3 text-bold">Cash yield</span></div>
          <div className="col hide-on-small-only m1"><span className="grey-text text-darken-3 text-bold">Equity yield</span></div>
          <div className="col s3 m2"><span className="grey-text text-darken-3 text-bold">Deal Status</span></div>      
        </div>
        : 
        <div>You don't have any investments proposals. Add one <a href="/evaluate">here</a>.</div> 
      }

        <ul className="collapsible">
          {this.state.investment_entities.map(investment_entity =>
                                            
            <li key={investment_entity.id}>
              <div className="row collapsible-header">
                <div className="col s5 m2">{investment_entity.address.split(',')[0]}</div>
                <div className="col s4 m2">${investment_entity.purchase_price.toLocaleString()}</div>
                <div className="col hide-on-small-only s2">${parseInt(investment_entity.price_per_square_foot).toLocaleString()}</div>
                <div className="col hide-on-small-only s2">${parseInt(investment_entity.net_cash).toLocaleString()}</div>
                <div className="col hide-on-small-only s1">{investment_entity.cash_yield.toLocaleString(undefined,{style: 'percent', maximumFractionDigits: 2})}</div>
                <div className="col hide-on-small-only s1">{investment_entity.equity_yield.toLocaleString(undefined,{style: 'percent', maximumFractionDigits: 2})}</div>
                <div className="col s3 m2">{investment_entity.deal_status}</div>
              </div>
              <div className="collapsible-body">
                  <div className="section">
                    <h5 className="deep-purple-text text-darken-4">Property details</h5>
                    <div><span className="text-bold">Address:</span> {investment_entity.address}</div>
                    <div><span className="text-bold">Purchase price:</span> ${parseInt(investment_entity.purchase_price).toLocaleString()}</div>
                    <div><span className="text-bold">Estimated value:</span> $[zestimate or equivalent]</div>
                    <div><span className="text-bold">Square footage:</span> {investment_entity.square_footage.toLocaleString()}</div>
                    <div><span className="text-bold">Building value:</span> ${parseInt(investment_entity.building_value).toLocaleString()}</div>
                  </div>

                  <div className="section">
                    <h5 className="deep-purple-text text-darken-4">Loan details</h5>
                    <div><span className="text-bold">Loan amount:</span> ${parseInt(investment_entity.purchase_price * (1-investment_entity.down_payment_percentage)).toLocaleString()}</div>
                    <div><span className="text-bold">Loan type:</span> 30-year fixed rate</div>
                    <div><span className="text-bold">Interest rate:</span> {investment_entity.interest_rate.toLocaleString(undefined,{style: 'percent', maximumFractionDigits: 3})}</div>
                    <div><span className="text-bold">Monthly debt-service:</span> ${parseInt(investment_entity.monthly_debt_service).toLocaleString()} </div>
                  </div>

                  <div className="divider"></div>
                  <div className="section">
                    <h5 className="deep-purple-text text-darken-4">Financial information</h5>
                    <div><span className="text-bold">Earnings to date:</span></div>
                    <div><span className="text-bold">Reserve fund ($):</span></div>
                    <ResponsiveContainer width="95%" height={400}>
                      <ComposedChart data={JSON.parse(investment_entity.graph_data)} margin={{ top: 30, right: 30, bottom: 30, left: 30 }}>
                        <Line name="Percent Equity" dataKey="PercentEquity" type="monotone" stroke="#8884d8" />
                        <Line name="Cash Yield" dataKey="CashYield" type="monotone" stroke="#82ca9d" />
                        <Line name="Total Cash Yield" dataKey="TotalCashYield" type="monotone" stroke="green" />
                        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                        <XAxis dataKey="Year" label={{ value: "Year", fontWeight: 700, dy: 10}} />
                        <YAxis type="number" domain={[0, 100]} allowDataOverflow={true} label={{ value: "Percent (%)", fontWeight: 700, position: "insideLeft", dx: -30, dy: 20}} />
                        <Tooltip />
                        <Legend layout="vertical" wrapperStyle={{top: 30, left: 100}} />
                      </ComposedChart>
                    </ResponsiveContainer>

                    <ResponsiveContainer width="95%" height={400}>
                      <ComposedChart stackOffset="sign" data={JSON.parse(investment_entity.graph_data)} margin={{ top: 30, right: 30, bottom: 30, left: 30 }}>
                        <Bar name="Annual Income" dataKey="Cash" type="monotone" fill="#82ca9d" />
                        <Bar name="Annual Debt Service" stackId="a" dataKey="DebtService" type="monotone" fill="blue" />
                        <Bar name="Annual Expenses" stackId="a" dataKey="Expenses" type="monotone" fill="red" />
                        <Bar name="Net Cash After Debt Service" dataKey="NetCash" type="monotone" fill="green" />
                        <Bar name="Taxable Income" dataKey="TaxableIncome" type="monotone" fill="black" />
                        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                        <XAxis dataKey="Year" label={{ value: "Year", fontWeight: 700, dy: 10}} />
                        <YAxis label={{ value: "Amount ($)", fontWeight: 700, position: "insideLeft", dx: -30, dy: 20}} />
                        <Tooltip formatter={(value) => new Intl.NumberFormat('en').format(value)} />
                        <Legend layout="vertical" wrapperStyle={{top: 30, left: 100}}/>
                      </ComposedChart>
                    </ResponsiveContainer>
                  </div>
                  <div className="divider"></div>
                  <div className="section">
                    <h5 className="deep-purple-text text-darken-4">History and Projections</h5>
                    <ResponsiveContainer width="95%" height={400}>
                      <ComposedChart data={JSON.parse(investment_entity.graph_data)} margin={{ top: 30, right: 30, bottom: 30, left: 30 }}>
                        <Bar name="Annual Cash Flow" dataKey="Cash" type="monotone" fill="#82ca9d" />
                        <Line name="Lifetime Income" dataKey="LifetimeIncome" type="monotone" stroke="green" />
                        <Line dataKey="Equity" type="monotone" stroke="#8884d8" />
                        <Line name="Equity Value" dataKey="EquityValue" type="monotone" stroke="red" />
                        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                        <XAxis dataKey="Year" label={{ value: "Year", fontWeight: 700, dy: 10}} />
                        <YAxis label={{ value: "Amount ($)", fontWeight: 700, position: "insideLeft", dx: -30, dy: 20}} />
                        <Tooltip />
                        <Legend layout="vertical" wrapperStyle={{top: 30, left: 100}}/>
                      </ComposedChart>
                    </ResponsiveContainer>
                  </div>
                  <div className="divider"></div>
                {investment_entity.investors 
                  ?
                  <div className="section">
                    <h5 className="deep-purple-text text-darken-4">Investor information</h5>
                    <div className="row">                    
                      <div className="col s2 text-bold">Name</div>
                      <div className="col s2 text-bold">Type</div>
                      <div className="col s2 text-bold">Equity</div>
                      <div className="col s2 text-bold">Profit and Loss Allocation</div>
                      <div className="col s2 text-bold">Capital Contributions</div>
                      <div className="col s2 text-bold">Address</div>
                    </div>
                    <ul>
                      {investment_entity.investors.map(investor =>

                        <li key={investor.id}>
                          <div className="row">                    
                            <div className="col s2">{investor.id}</div>
                            <div className="col s2">{investor.type}</div>
                            <div className="col s2">{investor.equity}%</div>
                            <div className="col s2">{investor.pl_alloc}%</div>
                            <div className="col s2">${investor.capital_contributions}</div>
                            <div className="col s2">{investor.address}</div>
                          </div>
                        </li>
                        
                      )}
                      
                    </ul>
                  </div>
                  : 
                  <div></div> 
                }

                  <div className="section row">
                    <div className="col s10 m3 offset-m8"><a href={"/deal/create/" + investment_entity.id} className="waves-effect waves-light blue-grey darken-2 btn"><i className="material-icons left">business_center</i>Generate Deal Sheet</a></div>
                  </div>
                  <div className="section row">
                    <div className="col s5 m2 offset-m8"><a href={"/evaluate/" + investment_entity.id} className="waves-effect waves-light btn"><i className="material-icons left">edit</i>Edit</a></div>
                    <div className="col s6 m2"><button className="waves-effect waves-light red accent-4 btn" onClick={() => this.deleteInvestmentEntity(investment_entity.id)}><i className="material-icons left">delete</i>Delete</button></div>
                  </div>
              </div>
            </li>
            
          )}
          
        </ul>

        <div className="row">
          <div className="col s7 offset-s3 m2 offset-m10">
            <i className="material-icons tooltipped form-icon blue-grey-text" data-position="bottom" data-tooltip="Import to Google Sheets">cloud_upload</i>
            <i className="material-icons tooltipped form-icon blue-grey-text" data-position="bottom" data-tooltip="Download as .xsl file">file_download</i>
          </div>
        </div>

      </div>



    );
  }
  
}

export default InvestmentDashboard;