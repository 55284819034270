import React, { Component } from 'react';
import axios from 'axios';
//import {PlacesAutocomplete} from './PlacesAutocomplete.component.js';
import AddressAutocomplete from './AddressAutocomplete.component';
import Materialize from 'materialize-css';
import { LineChart, ComposedChart, Bar, Line, Legend, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
//import { session } from '../../../server/config/passport.config';



class InvestmentEvaluator extends Component {
  constructor(props){
    super(props);
    this.handlePurchasePriceChange = this.handlePurchasePriceChange.bind(this);
    this.handleBuildingValueChange = this.handleBuildingValueChange.bind(this);
    this.handleDownPaymentPercentageChange = this.handleDownPaymentPercentageChange.bind(this);
    this.handleInterestRateChange = this.handleInterestRateChange.bind(this);
    this.handleMonthlyRentChange = this.handleMonthlyRentChange.bind(this);
    this.handleAnnualRentIncreaseChange = this.handleAnnualRentIncreaseChange.bind(this);
    this.handleVacancyRateChange = this.handleVacancyRateChange.bind(this);
    this.handleLandlordExpensesChange = this.handleLandlordExpensesChange.bind(this);
    this.handleSquareFootageChange = this.handleSquareFootageChange.bind(this);
    this.handleInsuranceChange = this.handleInsuranceChange.bind(this);
    this.handlePropertyTaxChange = this.handlePropertyTaxChange.bind(this);
    this.handleMiscFeesChange = this.handleMiscFeesChange.bind(this);
    this.handleManagementFeesChange = this.handleManagementFeesChange.bind(this);
    this.handleAddressChange = this.handleAddressChange.bind(this);
    this.handleAppreciationRateChange = this.handleAppreciationRateChange.bind(this);
    this.handlePropertyTypeChange = this.handlePropertyTypeChange.bind(this);
    this.addOrUpdateInvestmentEntity = this.addOrUpdateInvestmentEntity.bind(this);
    this.getPropertyDetails = this.getPropertyDetails.bind(this);
    this.toggleDetails = this.toggleDetails.bind(this);
        
    this.state = {
        investment_name: sessionStorage.getItem('investment_name'),
        address: sessionStorage.getItem('address'),
        property_type: sessionStorage.getItem('property_type') || "residential",
        purchase_price: Number(sessionStorage.getItem('purchase_price')),
        square_footage: Number(sessionStorage.getItem('square_footage')),
        building_value: Number(sessionStorage.getItem('building_value')), 
        closing_costs: Number(sessionStorage.getItem('closing_costs')),
        down_payment_percentage: Number(sessionStorage.getItem('down_payment_percentage') || .20),
        interest_rate: Number(sessionStorage.getItem('interest_rate')),
        monthly_rent: Number(sessionStorage.getItem('monthly_rent')),
        monthly_debt_service: Number(sessionStorage.getItem('monthly_debt_service')), 
        annual_rent_increase: Number(sessionStorage.getItem('annual_rent_increase')) || .03,
        appreciation_rate: Number(sessionStorage.getItem('appreciation_rate')) || .03,
        vacancy_rate: Number(sessionStorage.getItem('vacancy_rate')) || .05,
        landlord_expenses: Number(sessionStorage.getItem('landlord_expenses')), 
        insurance: Number(sessionStorage.getItem('insurance')), 
        property_taxes: Number(sessionStorage.getItem('property_taxes')), 
        misc_fees: Number(sessionStorage.getItem('misc_fees')), 
        management_fees: Number(sessionStorage.getItem('management_fees')), 
        principal_payments: JSON.parse(sessionStorage.getItem('principal_payments')) || [],
        aggregate_principal_payments: JSON.parse(sessionStorage.getItem('aggregate_principal_payments')) || [],
        interest_payments: JSON.parse(sessionStorage.getItem('principal_payments')) || [],
        graph_data: JSON.parse(sessionStorage.getItem('graph_data')) || [],
        isLoading: true,

        error: null,
      };

  }

  toggleDetails(e) {

    var id = e.target.getAttribute("data-target");
    var elem = document.getElementById(id);

    if(e.target.checked && elem.classList.contains("collapse")){
      elem.classList.remove("collapse");
    } else if (!e.target.checked && !elem.classList.contains("collapse")) {
      elem.classList.add("collapse");
    } else {
      e.target.checked = true;
      elem.classList.remove("collapse");
    }
  }

  
  handleAddressChange(address) {
    this.setState({ 
      address: address 
    }, ()=> {

      this.getPropertyDetails();
    });  
    sessionStorage.setItem('address', address);  
  }

  handlePropertyTypeChange(e) {
    this.setState({ property_type: e.target.value });
    sessionStorage.setItem('property_type', e.target.value);
  }
  
  handlePurchasePriceChange(e) {
    this.setState({ 
      purchase_price: e.target.value 
    }, ()=> {
      this.generateAmortizationSchedule();
    });
    sessionStorage.setItem('purchase_price', e.target.value);
  }
  
  handleSquareFootageChange(e) {
    this.setState({ square_footage: e.target.value });
    sessionStorage.setItem('square_footage', e.target.value);
  }
  
  handleBuildingValueChange(e) {
    this.setState({ building_value: e.target.value }, ()=> {
      this.generateGraphData()
    });
    sessionStorage.setItem('building_value', e.target.value);
  }
  
  handleDownPaymentPercentageChange(e) {
    var down_payment_percentage = e.target.value / 100;
    this.setState({ down_payment_percentage: down_payment_percentage }, ()=> {
      this.generateAmortizationSchedule()
    });
    sessionStorage.setItem('down_payment_percentage', down_payment_percentage);
  }
  
  handleInterestRateChange(e) {
    var interest_rate = e.target.value / 100;
    this.setState({ interest_rate: interest_rate }, ()=> {
      this.generateAmortizationSchedule()
    });
    sessionStorage.setItem('interest_rate', interest_rate);
  }
  
  handleMonthlyRentChange(e) {
    this.setState({ monthly_rent: e.target.value }, ()=> {
      this.generateGraphData()
    });
    sessionStorage.setItem('monthly_rent', e.target.value);
  }

  handleAnnualRentIncreaseChange(e) {
    var annual_rent_increase = e.target.value / 100;
    this.setState({ annual_rent_increase: annual_rent_increase }, ()=> {
      this.generateGraphData()
    });
    sessionStorage.setItem('annual_rent_increase', annual_rent_increase);
  }

  handleAppreciationRateChange(e) {
    var appreciation_rate = e.target.value / 100;
    this.setState( { appreciation_rate: appreciation_rate }, () => {
      this.generateGraphData()
    });

    sessionStorage.setItem('appreciation_rate', appreciation_rate);
  }

  handleVacancyRateChange(e) {
    var vacancy_rate = e.target.value / 100;
    this.setState({ vacancy_rate: vacancy_rate }, ()=> {
      this.generateGraphData()
    });
    sessionStorage.setItem('vacancy_rate', vacancy_rate);
  }
  
  handleLandlordExpensesChange(e) {
    this.setState({ landlord_expenses: e.target.value }, ()=> {
      this.generateGraphData()
    });
    sessionStorage.setItem('landlord_expenses', e.target.value);
  }
   
  handleInsuranceChange(e) {
    this.setState({ insurance: e.target.value }, ()=> {
      this.generateGraphData()
    });
    sessionStorage.setItem('insurance', e.target.value);
  }
  
  handlePropertyTaxChange(e) {
    this.setState({ property_taxes: e.target.value }, ()=> {
      this.generateGraphData()
    });
    sessionStorage.setItem('property_taxes', e.target.value);
  }
  
  handleMiscFeesChange(e) {
    this.setState({ misc_fees: e.target.value }, ()=> {
      this.generateGraphData()
    });
    sessionStorage.setItem('misc_fees', e.target.value);
  }
  
  handleManagementFeesChange(e) {
    this.setState({ management_fees: e.target.value }, ()=> {
      this.generateGraphData()
    });
    sessionStorage.setItem('management_fees', e.target.value);
  }
  

  estimateClosingCosts(){
    //let purchase_price = document.querySelector('#purchase_price').value;
    var estimated_closing_costs = this.state.purchase_price * '.03';
    sessionStorage.setItem('closing_costs', estimated_closing_costs);
    return estimated_closing_costs;
  }
  
  
  estimateAcquisitionCosts(){
    var estimated_acquisition_costs = parseInt(this.state.purchase_price) + this.estimateClosingCosts();
    return estimated_acquisition_costs;
  }
  
  getLoanAmount(){
    var loan_amount = this.state.purchase_price * (1-this.state.down_payment_percentage);
    return loan_amount;
  }
  
  cashAtClose(){
    var cash_at_close = this.state.purchase_price - this.getLoanAmount() + this.estimateClosingCosts();
    return cash_at_close;
  }
  
  getMonthlyDebtService(){
    var payment = 0;
    var loan_amount = this.getLoanAmount();
    var rate = this.state.interest_rate / 12; //divide by 12 to get the monthly interest rate
    var n = 360; //number of payments, in this case 360 for a 30 year fixed loan (12 months a year)

    payment = loan_amount * (rate * Math.pow(1+rate, n)) / (Math.pow(1+rate, n) - 1);
    payment = (payment == null || isNaN(payment)) ? 0 : payment;

    sessionStorage.setItem("monthly_debt_service", payment);

    return payment;
  }
  
  getAnnualIncome(){
    var annual_income = (parseInt(this.state.monthly_rent) * 12) * (1 - this.state.vacancy_rate);
    annual_income = (annual_income == null || isNaN(annual_income)) ? 0 : annual_income;
    return annual_income;
  }
  
  getAnnualExpenses(){
    var annual_expenses = (parseInt(this.state.landlord_expenses) + parseInt(this.state.insurance) + parseInt(this.state.property_taxes) + parseInt(this.state.misc_fees) + parseInt(this.state.management_fees))*12;
    annual_expenses = (annual_expenses == null || isNaN(annual_expenses)) ? 0 : annual_expenses;
    return annual_expenses;
  }

  getAnnualExpensesForYear(year, growth_rate){
    var annual_expenses = (parseInt(this.state.landlord_expenses) + parseInt(this.state.insurance) + parseInt(this.state.property_taxes) + parseInt(this.state.misc_fees) + parseInt(this.state.management_fees))*12;
    annual_expenses = (annual_expenses == null || isNaN(annual_expenses)) ? 0 : (annual_expenses * Math.pow((1+growth_rate), year));
    //console.log("annual expenses in year: " + year + " are " + annual_expenses);
    return annual_expenses;
  }
  
  getAnnualDebtService(){
    var annual_debt_service = this.getMonthlyDebtService()*12;
    annual_debt_service = (annual_debt_service == null || isNaN(annual_debt_service)) ? 0 : annual_debt_service;
    return annual_debt_service;
  }
  
  getAverageEquityIncrease(){
    
    var months_of_payments = 84; //7 years of payments
    var total_principal_payments = 0;
    
    for(var count = 0; count < months_of_payments; count++){
      total_principal_payments += this.state.principal_payments[count];
    }

    var average_equity_increase = (total_principal_payments / months_of_payments) * 12;
    average_equity_increase = (average_equity_increase == null || isNaN(average_equity_increase)) ? 0 : average_equity_increase;
    
    return average_equity_increase;
  }


  getInterestPaymentForYear(year){
    var interest_payment_for_year = 0;
    var first_payment = year*12
    var last_payment = first_payment+12

    for(var count = first_payment; count < last_payment; count++){
      interest_payment_for_year += this.state.interest_payments[count];
    }

    interest_payment_for_year = (interest_payment_for_year == null || isNaN(interest_payment_for_year)) ? 0 : interest_payment_for_year;
    return interest_payment_for_year;
  }


  getAverageInterestPayment(years){
    
    var months_of_payments = years * 12;
    var total_interest_payments = 0;
    
    for(var count = 0; count < months_of_payments; count++){
      total_interest_payments += this.state.interest_payments[count];
    }

    var average_interest_payment = (total_interest_payments / months_of_payments) * 12;
    average_interest_payment = (average_interest_payment == null || isNaN(average_interest_payment)) ? 0 : average_interest_payment;
    
    return average_interest_payment;
  }
  

  getEquityYield(){
    var equity_yield = this.getAverageEquityIncrease() / this.cashAtClose();
    equity_yield = (equity_yield == null || isNaN(equity_yield)) ? 0 : equity_yield;
    return equity_yield;
  }
  

  getNetCash(){
    var net_cash = this.getAnnualIncome() - this.getAnnualExpenses() - this.getAnnualDebtService();
    net_cash = (net_cash == null || isNaN(net_cash)) ? 0 : net_cash;
    return net_cash;
  }


  getNetCashForYear(year, growth_rate){
    var net_cash = this.getIncomeInYear(year, growth_rate) - this.getAnnualExpensesForYear(year, growth_rate) - this.getAnnualDebtService();
    net_cash = (net_cash == null || isNaN(net_cash)) ? 0 : net_cash;
    //console.log("Net cash after debt service in year " + year + " is: " + net_cash);
    return net_cash;
  }


  getTaxableIncomeForYear(year, annual_rent_increase, growth_rate){
    var taxable_income = this.getIncomeInYear(year, annual_rent_increase) - this.getAnnualExpensesForYear(year, growth_rate) - this.getInterestPaymentForYear(year) - this.getAnnualDepreciation();
    taxable_income = (taxable_income == null || isNaN(taxable_income)) ? 0 : taxable_income;
    return taxable_income;
  }
  

  getCashYield(){
    var cash_yield = this.getNetCash() / this.cashAtClose();
    cash_yield = (cash_yield == null || isNaN(cash_yield)) ? 0 : cash_yield;
    return cash_yield;
  }


  getCashYieldForYear(year, growth_rate){
    var cash_yield = (this.getNetCashForYear(year, growth_rate)) / this.cashAtClose();
    cash_yield = (cash_yield == null || isNaN(cash_yield)) ? 0 : cash_yield;
    return cash_yield;
  }
  

  getPricePerSquareFoot(){
    var price_per_square_foot = this.state.purchase_price / this.state.square_footage;
    price_per_square_foot = (price_per_square_foot == null || isNaN(price_per_square_foot)) ? 0 : price_per_square_foot;
    return price_per_square_foot;
  }
  

  generateAmortizationSchedule(){
    //get starting balance of the loan
    var balance = this.getLoanAmount();

    //Calculate the per month interest rate
    var monthly_rate = this.state.interest_rate/12;
    var payment = this.getMonthlyDebtService();
    var terms = 360; //assume 30-year fixed mortgage for now. TODO: Make this adjustable
    var interest_payments = [];
    var principal_payments = [];  
    var aggregate_principal_payment = 0;
    var aggregate_principal_payments = [];
      
    /**
     * Loop that calculates the monthly Loan amortization amounts then adds 
     * them to the return string 
     */
    for (var count = 0; count < terms; ++count)
    { 
      //in-loop interest amount holder
      var interest_payment = 0;
      
      //in-loop monthly principal amount holder
      var monthly_principal = 0;
      
      //calc the in-loop interest amount and add to interest payments array
      interest_payment = balance * monthly_rate;
      interest_payments.push(interest_payment);
   
      //calc the in-loop monthly principal and add to principal payments array
      monthly_principal = payment - interest_payment;
      principal_payments.push(monthly_principal);

      aggregate_principal_payment += monthly_principal;
      aggregate_principal_payments.push(aggregate_principal_payment);

  
      //update the balance for each loop iteration
      balance = balance - monthly_principal;		
    }
    
    this.setState({
      interest_payments: interest_payments,
      principal_payments: principal_payments,
      aggregate_principal_payments: aggregate_principal_payments
    }, ()=> {
      sessionStorage.setItem('principal_payments', JSON.stringify(principal_payments));
      sessionStorage.setItem('aggregate_principal_payments', JSON.stringify(principal_payments));
      sessionStorage.setItem('interest_payments', JSON.stringify(interest_payments));
      this.generateGraphData();
    });

  }
  

  getAnnualDepreciation() {
    //commercial properties are depreciated over 39 years
    //residential properties are depreciated over 27.5 years
    //only the value of the building can be depreciated
    var useful_life = 0;
    if (this.state.property_type==="residential"){
      useful_life = 27.5;
    } else if (this.state.property_type==="commercial"){
      useful_life = 39;
    } else {
      useful_life = 1000000;
    }

    var annual_depreciation = this.state.building_value / useful_life;
    return annual_depreciation;
  }

  getIncomeInYear(year, growth_rate) {
    var income = this.getAnnualIncome();

    income = income * Math.pow((1+growth_rate), year);

    //console.log("Income in year: " + year + " is " + income);
    return income;
  }


  getTotalEquityAfterYear(year) {
    var total_equity = 0;
    var initial_equity = (this.state.down_payment_percentage * this.state.purchase_price);
    var months = year * 12;
    var accrued_equity = 0;

    if(months>=1){
      accrued_equity = this.state.aggregate_principal_payments[months-1];
    }

    total_equity = initial_equity + accrued_equity;

    return total_equity;
  }


  getValueOfPropertyInYear(year, appreciation_rate) {
    var property_value = this.state.purchase_price;

    property_value = this.state.purchase_price * Math.pow((1+appreciation_rate), year);

    return property_value;
  }


  generateGraphData() {
    var graph_data = [];

    var cash = 0;
    var net_cash = 0;
    var debt_service = 0;
    var expenses = 0;
    var taxable_income = 0;
    var lifetime_income = 0;
    var cash_yield = this.getCashYieldForYear(0, .03);
    var total_cash_yield = 0;
    var equity = this.getTotalEquityAfterYear(0);
    var percent_equity = this.state.down_payment_percentage;
    var equity_value = 0;

    for(var year = 0; year<=30; year++){

        cash = this.getIncomeInYear(year, this.state.annual_rent_increase);
        debt_service = this.getAnnualDebtService();
        expenses = this.getAnnualExpensesForYear(year, .03);
        net_cash = this.getNetCashForYear(year, .03);
        taxable_income = this.getTaxableIncomeForYear(year, this.state.annual_rent_increase, .03);
        lifetime_income += cash;
        cash_yield = this.getCashYieldForYear(year, this.state.annual_rent_increase);
        equity = this.getTotalEquityAfterYear(year);
        percent_equity = (this.getTotalEquityAfterYear(year) / this.state.purchase_price);
        total_cash_yield += cash_yield;

        equity_value = percent_equity * this.getValueOfPropertyInYear(year, this.state.appreciation_rate);

      if(year <= 7 || year === 10 || year === 15 || year === 30){
        graph_data.push({
          Year: Number(year.toFixed(2)), 
          Cash: Number(cash.toFixed(2)), 
          DebtService: Number(debt_service.toFixed(2)),
          Expenses: Number(expenses.toFixed(2)),
          NetCash: Number(net_cash.toFixed(2)),
          TaxableIncome: Number(taxable_income.toFixed(2)),
          LifetimeIncome: Number(lifetime_income.toFixed(2)),
          CashYield: Number((cash_yield*100).toFixed(2)), 
          TotalCashYield: Number((total_cash_yield*100).toFixed(2)) || 0, 
          Equity: Number(equity.toFixed(2)), 
          PercentEquity: Number((percent_equity*100).toFixed(2)) || 0, 
          EquityValue: Number(equity_value.toFixed(2)) || 0
        });
      }
    }


    this.setState({
      graph_data: graph_data,
    }, ()=> {
      sessionStorage.setItem('graph_data', JSON.stringify(graph_data));
    })
  }


  getSalePriceData(){
    var options = {
      method: 'GET',
      url: 'https://realty-mole-property-api.p.rapidapi.com/salePrice',
      withCredentials: false,
      params: {compCount: '5', address: this.state.address},
      headers: {
        'x-rapidapi-host': 'realty-mole-property-api.p.rapidapi.com',
        'x-rapidapi-key': 'a011df35f2msha5c8dfe9f7f6958p152ec9jsn8414ab092e24'
      }
    };

    Materialize.toast({html: 'Retrieving property price', classes: 'price-data-toast', displayLength: 'indefinite'})
    var toastElement = document.querySelector('.price-data-toast');
    var toastInstance = Materialize.Toast.getInstance(toastElement);

    axios.request(options)
      .then(response =>
          this.setState({
            purchase_price: response.data.price,
            insurance: (response.data.price / 2000).toFixed(),
            sale_price_data: response.data
          })
        ).then( () => {
          console.log("Estimated price retrieved.");
          console.log(this.state.sale_price_data);

          sessionStorage.setItem('purchase_price', this.state.purchase_price);
          this.generateAmortizationSchedule();
          toastInstance.dismiss();
      }).catch(function (error) {
        console.error(error);
        toastInstance.dismiss();
        Materialize.toast({html: 'No property price data found', classes: 'red darken-4', displayLength: 3000});
      });
  }


  calculatePropertyTaxes(property_taxes_data){
    var monthly_property_taxes = 0;

    if(property_taxes_data){
      var tax_data_keys = Object.keys(property_taxes_data);
      var recent_tax_year = Math.max(...tax_data_keys);

      monthly_property_taxes = (property_taxes_data[recent_tax_year].total / 12).toFixed(0)
    }

    return monthly_property_taxes;
  }


  calculateBuildingValue(tax_assessment_data){
    var building_value = 0;

    if(tax_assessment_data){
      var tax_data_keys = Object.keys(tax_assessment_data);
      var recent_tax_year = Math.max(...tax_data_keys);

      building_value = tax_assessment_data[recent_tax_year].improvements;
    }
    return building_value;
  }
  

  getPropertyData(){
    var options = {
      method: 'GET',
      url: 'https://realty-mole-property-api.p.rapidapi.com/properties',
      withCredentials: false,
      params: {compCount: '5', address: this.state.address},
      headers: {
        'x-rapidapi-host': 'realty-mole-property-api.p.rapidapi.com',
        'x-rapidapi-key': 'a011df35f2msha5c8dfe9f7f6958p152ec9jsn8414ab092e24'
      }
    };

    Materialize.toast({html: 'Retrieving property information', classes: 'property-data-toast', displayLength: 'indefinite'});
    var toastElement = document.querySelector('.property-data-toast');
    var toastInstance = Materialize.Toast.getInstance(toastElement);

    axios.request(options)
      .then(response =>
          this.setState({
            bedrooms: response.data[0].bedrooms,
            bathrooms: response.data[0].bathrooms,
            square_footage: response.data[0].squareFootage,
            lot_size: response.data[0].lotSize,
            year_built: response.data[0].yearBuilt,
            property_taxes: this.calculatePropertyTaxes(response.data[0].propertyTaxes),
            property_tax_data: response.data[0].property_taxes,
            building_value: this.calculateBuildingValue(response.data[0].taxAssessment),
            tax_assessment_data: response.data[0].taxAssessment,
            property_data: response.data[0]
          })
        ).then( () => {
          console.log("Property data retrieved.");
          console.log(this.state.property_data);

          sessionStorage.setItem('square_footage', this.state.square_footage);
          sessionStorage.setItem('property_taxes', this.state.property_taxes);
          sessionStorage.setItem('building_value', this.state.building_value);
          

          toastInstance.dismiss();
      }).catch(function (error) {
        console.error(error);
        toastInstance.dismiss();
        Materialize.toast({html: 'No property details found', classes: 'red darken-4', displayLength: 3000});
      });

  }


  getRentEstimate(){
    var options = {
      method: 'GET',
      url: 'https://realty-mole-property-api.p.rapidapi.com/rentalPrice',
      withCredentials: false,
      params: {address: this.state.address, compCount: '5'},
      headers: {
        'x-rapidapi-host': 'realty-mole-property-api.p.rapidapi.com',
        'x-rapidapi-key': 'a011df35f2msha5c8dfe9f7f6958p152ec9jsn8414ab092e24'
      }
    };

    Materialize.toast({html: 'Retrieving rental data', classes: 'rental-data-toast', displayLength: 'indefinite'});
    var toastElement = document.querySelector('.rental-data-toast');
    var toastInstance = Materialize.Toast.getInstance(toastElement);

    axios.request(options)
      .then(response =>
          this.setState({
            monthly_rent: (response.data.rent || 0).toFixed(0),
            landlord_expenses: (response.data.rent / 10 || 0).toFixed(0),
            management_fees: (response.data.rent / 10 || 0).toFixed(0), 
            rent_data: response.data
          })
        ).then( () => {
          console.log("Estimated rent retrieved.");
          console.log(this.state.monthly_rent);

          sessionStorage.setItem('monthly_rent', this.state.monthly_rent);
          this.generateAmortizationSchedule();
          toastInstance.dismiss();
      }).catch(function (error) {
        console.error(error);
        toastInstance.dismiss();
        Materialize.toast({html: 'Rental estimate not found', classes: 'red darken-4', displayLength: 3000});
      });
  }


  getPropertyDetails(){
    console.log("Retrieving property details...");
    this.getSalePriceData();
    this.getPropertyData();
    this.getRentEstimate();
  }


  addOrUpdateInvestmentEntity() {
    var investment_entity_id = this.props.match.params.id;
    if(investment_entity_id){
      console.log("Investment entity already exists. Updating.");
      this.updateInvestmentEntity(investment_entity_id);
    } else {
      console.log("Creating new investment entity.");
      this.addInvestmentEntity()
    }
  }
  
  
  addInvestmentEntity() {
    var net_cash = this.getNetCash();
    var cash_yield = this.getCashYield();
    var equity_yield = this.getEquityYield();
    var price_per_square_foot = this.getPricePerSquareFoot();
    var user_id = this.props.user_id;

    if(user_id != null){
      axios.post(process.env.REACT_APP_API_ENDPOINT + '/api/investment_entity/', {
        name: this.state.investment_name || '',
        address: this.state.address || '',
        property_type: this.state.property_type || 'residential',
        purchase_price: this.state.purchase_price || 0,
        down_payment_percentage: this.state.down_payment_percentage || 0,
        closing_costs: this.state.closing_costs || 0,
        cash_at_close: this.cashAtClose() || 0,
        interest_rate: this.state.interest_rate || 0,
        building_value: this.state.building_value || 0,
        square_footage: this.state.square_footage || 0,
        price_per_square_foot: price_per_square_foot || 0,
        monthly_rent: this.state.monthly_rent || 0,
        monthly_debt_service: this.state.monthly_debt_service || 0,
        landlord_expenses: this.state.landlord_expenses || 0,
        insurance: this.state.insurance || 0,
        property_taxes: this.state.property_taxes || 0,
        misc_fees: this.state.misc_fees || 0,
        management_fees: this.state.management_fees || 0,
        graph_data: JSON.stringify(this.state.graph_data) || '',
        net_cash: net_cash || 0,
        cash_yield: cash_yield || 0,
        equity_yield: equity_yield || 0,
        deal_status: this.state.deal_status || 'Pending',
        user_id: user_id,

      }).then(resp => {
        console.log(resp.data);
        window.location = "/investments";
      }).catch(error => {
          console.log(error);
      });
    } else {
      window.alert("You must log in before saving an investment entity");
    }
  }

  updateInvestmentEntity(id) {
    var net_cash = this.getNetCash();
    var cash_yield = this.getCashYield();
    var equity_yield = this.getEquityYield();
    var price_per_square_foot = this.getPricePerSquareFoot();
    var user_id = this.props.user_id;
    

    if(user_id != null){
      axios.post(process.env.REACT_APP_API_ENDPOINT + '/api/investment_entity/' + id, {
        name: this.state.investment_name || '',
        address: this.state.address || '',
        property_type: this.state.property_type || 'residential',
        purchase_price: this.state.purchase_price || 0,
        down_payment_percentage: this.state.down_payment_percentage || 0,
        closing_costs: this.state.closing_costs || 0,
        cash_at_close: this.cashAtClose() || 0,
        interest_rate: this.state.interest_rate || 0,
        building_value: this.state.building_value || 0,
        square_footage: this.state.square_footage || 0,
        price_per_square_foot: price_per_square_foot || 0,
        monthly_rent: this.state.monthly_rent || 0,
        monthly_debt_service: this.state.monthly_debt_service || 0,
        landlord_expenses: this.state.landlord_expenses || 0,
        insurance: this.state.insurance || 0,
        property_taxes: this.state.property_taxes || 0,
        misc_fees: this.state.misc_fees || 0,
        management_fees: this.state.management_fees || 0,
        graph_data: JSON.stringify(this.state.graph_data) || '',
        net_cash: net_cash || 0,
        cash_yield: cash_yield || 0,
        equity_yield: equity_yield || 0,
        deal_status: this.state.deal_status || 'Pending',
        user_id: user_id

      }).then(resp => {
        console.log(resp.data);
        window.location = "/investments";
      }).catch(error => {
          console.log(error);
      });
    } else {
      window.alert("You must log in before saving an investment entity");
    }
  }

  getInvestmentEntity(property_id) {

    var user_id = this.props.user_id;

    if(user_id != null){
      
      axios.get(process.env.REACT_APP_API_ENDPOINT + '/api/investment_entity/edit/'  + user_id + '/' + property_id)
      .then(result => 
        {
          console.log(result.data);
        });
      
      

      axios.get(process.env.REACT_APP_API_ENDPOINT + '/api/investment_entity/edit/' + user_id + '/' + property_id)
        .then(result =>  
          this.setState({
            investment_name: result.data.name,
            address: result.data.address,
            property_type: result.data.property_type,
            purchase_price: result.data.purchase_price,
            down_payment_percentage: result.data.down_payment_percentage,
            closing_costs: result.data.closing_costs,
            interest_rate: result.data.interest_rate,
            building_value: result.data.building_value,
            square_footage: result.data.square_footage,
            price_per_square_foot: result.data.price_per_square_foot,
            monthly_rent: result.data.monthly_rent,
            monthly_debt_service: result.data.monthly_debt_service,
            landlord_expenses: result.data.landlord_expenses,
            insurance: result.data.insurance,
            property_taxes: result.data.property_taxes,
            misc_fees: result.data.misc_fees,
            management_fees: result.data.management_fees,
            graph_data: JSON.parse(result.data.graph_data),
            net_cash: result.data.net_cash,
            cash_yield: result.data.cash_yield,
            equity_yield: result.data.equity_yield,
            deal_status: result.data.deal_status,
            isLoading: false,
          })
        ).then( () => {
          this.setInterestRate()
          this.checkPropertyTypeRadioButton()
          this.generateAmortizationSchedule()
        }).catch(error => this.setState({
          error,
          isLoading: false
        }, ()=> {
          window.location = "/evaluate";
        }));

    } else {
      window.alert("You must log in before retrieving an investment entity");
    }

  }

  setInterestRate(){
    var interest_rate_input = document.getElementById("interest_rate");
    interest_rate_input.value = this.state.interest_rate * 100;
    sessionStorage.setItem('interest_rate', this.state.interest_rate);

  }

  checkPropertyTypeRadioButton(){

    var radio_button = document.getElementById("residential-radio-button");

    if (this.state.property_type==="residential"){
      radio_button = document.getElementById("residential-radio-button");
    } else if (this.state.property_type==="commercial"){
      radio_button = document.getElementById("commercial-radio-button");
    } 

    if(radio_button != null){
      radio_button.checked = true;
    }
  }

  componentDidMount() {

    
    if(this.props.match.params.id){
      sessionStorage.clear();
      this.getInvestmentEntity(this.props.match.params.id)
    } else{

      this.setState({
        isLoading: false,
      }, () => {
        this.checkPropertyTypeRadioButton()
        this.generateAmortizationSchedule()
      })

    }


  }
  
  render(){
    
    /* 
    this was preventing tooltips from working, but seems unnecessary with changing defaultValue to value
    if (this.state.isLoading){
      return( <div id="loading">Loading...</div>);
    }
    */

    return(

      <span>

      <div className="container">

        
        <div className="row">
          <form className="col s12 m8">

          {/*
          <div className="switch">
            <label>
            <span className="tooltipped" data-position="top" data-tooltip="We recommend selecting this if you are new to property investing">Highlight outputs</span>
              <input type="checkbox"/>
              <span className="lever"></span>
            </label>
          </div>
          */}
          
            <h6 className="deep-purple-text text-darken-4">Property details</h6>
            <div className="row">   
              <span className="col s12 m6">         
                <AddressAutocomplete defaultValue = {this.state.address} parentCallback = {this.handleAddressChange} />  
              </span>              
            </div>
            <div className="row">
              <div onChange={this.handlePropertyTypeChange} >
                <span className="tooltipped col s3" data-position="top" data-tooltip="Property type impacts how much annual depreciation can write off on your taxes">
                  Property type
                </span>

                <label className="col s3">
                  <input id="residential-radio-button" value="residential" name="group1" type="radio" />
                  <span>Residential</span>
                </label>


                <label className="col s3">
                  <input id="commercial-radio-button" value="commercial" name="group1" type="radio" />
                  <span>Commercial</span>
                </label>
 
              </div>
            </div>

            <h6 className="deep-purple-text text-darken-4">Purchase details</h6>
            <div className="row">
              <div className="input-field col s6 m4">
                <input placeholder="1,500,000" value={String(this.state.purchase_price)} id="purchase_price" type="text" className="validate" onChange={this.handlePurchasePriceChange} />
                <label htmlFor="purchase_price" className="active">Purchase price</label>
              </div>
              
              <div className="input-field col s6 m4">
                <input value={this.state.down_payment_percentage * 100} type="text" min="0" max="100" id="down_payment" className="validate" onChange={this.handleDownPaymentPercentageChange} />
                <label htmlFor="down_payment" className="active">Down payment (%)</label>
              </div>
              
              <div className="input-field col s6 m4">
                <input placeholder="4.4" defaultValue={(this.state.interest_rate * 100).toFixed(3).replace(/\.?0*$/, '')} type="text" min="0" max="100" id="interest_rate" className="validate" onChange={this.handleInterestRateChange} />
                <label htmlFor="down_payment" className="active">Interest rate (%)</label>
              </div>

              <div className="input-field col s6 m4">
                <input placeholder="1,000,000" value={this.state.building_value} id="building_value" type="text" className="validate" onChange={this.handleBuildingValueChange} />
                <label htmlFor="building_value" className="active">
                  <span className="tooltipped" data-position="top" data-tooltip="Building value is eligible for depreciation. It is the total cost of the property minus the cost of the land its built on.">
                    Building value
                  </span>
                </label>
              </div>
              
              <div className="input-field col s6 m4">
                <input placeholder="20,000" value={this.state.square_footage} id="square_footage" type="text" className="validate" onChange={this.handleSquareFootageChange} />
                <label htmlFor="square_footage" className="active">Square footage</label>
              </div>


              <div className="input-field col s6 m4">
                <input disabled value={"$" + parseInt(this.getPricePerSquareFoot()).toLocaleString()} id="price_per_sqft" type="text" />
                <label htmlFor="price_per_sqft" className="active">Price per sqft.</label>
              </div>

              <div className="input-field col s6 m4">
                <input placeholder="2.6%" value={parseInt(this.state.appreciation_rate * 100)} id="appreciation_rate" type="text" className="validate" onChange={this.handleAppreciationRateChange}/>
                <label htmlFor="appreciation_rate" className="active">Appreciation rate (%)</label>
              </div>

            </div>
            
            
            <h6 className="deep-purple-text text-darken-4">Income</h6>
            <div className="row">
              <div className="input-field col s12 m4">
                <input placeholder="35,000" value={this.state.monthly_rent} id="monthly_rent" type="text" className="validate" onChange={this.handleMonthlyRentChange}/>
                <label htmlFor="monthly_rent" className="active">Monthly rent</label>
              </div>
              <div className="input-field col s6 m4">
                <input placeholder="2.6%" value={parseInt(this.state.annual_rent_increase * 100)} id="annual_rent_increase" type="text" className="validate" onChange={this.handleAnnualRentIncreaseChange}/>
                <label htmlFor="annual_rent_increase" className="active">Annual rent increase (%)</label>
              </div>
              <div className="input-field col s6 m4">
                <input placeholder="5%" value={this.state.vacancy_rate * 100} id="vacancy_rate" type="text" className="validate" onChange={this.handleVacancyRateChange}/>
                <label htmlFor="vacancy_rate" className="active">Vacancy rate (%)</label>
              </div>
            </div>


            <h6 className="deep-purple-text text-darken-4">Expenses (monthly)</h6>
            <div className="row">
              <div className="input-field col s6 m4">
                <input placeholder="25,000" value={this.state.landlord_expenses} id="landlord_expenses" type="text" className="validate" onChange={this.handleLandlordExpensesChange}/>
                <label htmlFor="landlord_expenses" className="active">
                  <span className="tooltipped" data-position="top" data-tooltip="Landlord expenses are esimated at 10% of the monthly rent.">
                    Landlord expenses
                  </span>
                </label>
              </div>
              <div className="input-field col s6 m4">
                <input placeholder="200" value={this.state.insurance} id="insurance" type="text" className="validate" onChange={this.handleInsuranceChange}/>
                <label htmlFor="insurance" className="active">
                  <span className="tooltipped" data-position="top" data-tooltip="Monthly insurance payments are esimated at .05% of the purchase price. Insurance varies significantly by area, type of property and other factors. We recommend consulting an insurance professional for a more accurate value.">
                      Insurance
                  </span>
                </label>
              </div>
              <div className="input-field col s6 m4">
                <input placeholder="250" value={this.state.property_taxes} id="property_taxes" type="text" className="validate" onChange={this.handlePropertyTaxChange}/>
                <label htmlFor="property_taxes" className="active">Property taxes</label>
              </div>
              <div className="input-field col s6 m4">
                <input placeholder="500" value={this.state.misc_fees} id="misc_fees" type="text" className="validate" onChange={this.handleMiscFeesChange}/>
                <label htmlFor="misc_fees" className="active">Misc. fees (e.g. HOA)</label>
              </div>
              <div className="input-field col s6 m4">
                <input placeholder="1,000" value={this.state.management_fees} id="management_fees" type="text" className="validate" onChange={this.handleManagementFeesChange}/>
                <label htmlFor="management_fees" className="active">
                    <span className="tooltipped" data-position="top" data-tooltip="Management fees are esimated at 10% of the monthly rent.">
                    Management fees
                    </span>
                </label>
              </div>
            </div>
          </form>
          
          <div className="col s12 m4">
          
            <div className="output">
  
                <div className="switch row">
                  <label>
                    <h6 className="deep-purple-text text-darken-4 col s9">Closing details</h6> 
                    <input type="checkbox" defaultChecked data-target="closing-details-collapsible" onChange={this.toggleDetails}/>
                    <span className="lever"></span>
                  </label>
                </div>

                <div id="closing-details-collapsible">
                  <div className="row blue-grey lighten-5"> 
                    <div className="col s7">Total acquisition cost</div> 
                    <div className="col s4 offset-s1">${parseInt(this.estimateAcquisitionCosts()).toLocaleString()}</div>
                  </div>
                  <div className="row blue-grey lighten-5"> 
                    <div className="col s7">Down payment</div> 
                    <span className="col s4 offset-s1">${parseInt(this.state.down_payment_percentage * this.state.purchase_price).toLocaleString()}</span>
                  </div>
                  <div className="row blue-grey lighten-5">   
                    <div className="col s7">
                      <span className="tooltipped" data-position="top" data-tooltip="Closing costs are esimated at 3% of the purchase price">
                        Closing costs 
                      </span> 
                    </div>
                    <span className="col s4 offset-s1">
                      ${parseInt(this.estimateClosingCosts()).toLocaleString()}
                    </span>
                  </div>
                </div>
                <div className="row blue-grey lighten-3 text-bold"> 
                  <div className="col s7">Cash required at close</div>
                  <span className="col s4 offset-s1">${parseInt(this.cashAtClose()).toLocaleString()}</span>
                </div>
            </div>
            
            <div className="output">
                <div className="switch row">
                  <label>
                    <h6 className="deep-purple-text text-darken-4 col s9">Loan details</h6> 
                    <input type="checkbox" defaultChecked data-target="loan-details-collapsible" onChange={this.toggleDetails}/>
                    <span className="lever"></span>
                  </label>
                </div>
                <div id="loan-details-collapsible">
                  <div className="row blue-grey lighten-5"> 
                    <div className="col s7">Loan amount</div> 
                    <div className="col s4 offset-s1">${parseInt(this.getLoanAmount()).toLocaleString()}</div>
                  </div>
                </div>
                <div className="row blue-grey lighten-3 text-bold"> 
                  <div className="col s7">Monthly debt service ({this.state.interest_rate.toLocaleString(undefined,{style: 'percent', maximumFractionDigits: 3})})</div> 
                  <div className="col s4 offset-s1">${parseInt(this.getMonthlyDebtService()).toLocaleString()}</div>
                </div>
            </div>


            <div className="output">
                <div className="switch row">
                  <label>
                    <h6 className="deep-purple-text text-darken-4 col s9">Cash flow</h6> 
                    <input type="checkbox" defaultChecked data-target="cash-flow-details-collapsible" onChange={this.toggleDetails}/>
                    <span className="lever"></span>
                  </label>
                </div>
                <div id="cash-flow-details-collapsible">
                  <div className="row blue-grey lighten-5"> 
                    <div className="col s7">Annual income</div> 
                    <div className="col s4 offset-s1">${parseInt(this.getAnnualIncome()).toLocaleString()}</div>
                  </div>
                  <div className="row blue-grey lighten-5"> 
                    <div className="col s7">Annual expenses</div> 
                    <div className="col s4 offset-s1">${parseInt(this.getAnnualExpenses()).toLocaleString()}</div>
                  </div>
                  <div className="row blue-grey lighten-5"> 
                    <div className="col s7">Annual debt service</div> 
                    <div className="col s4 offset-s1">${parseInt(this.getAnnualDebtService()).toLocaleString()}</div>
                  </div>
                  <div className="row blue-grey lighten-5"> 
                    <div className="col s7">Net cash after debt service</div> 
                    <div className="col s4 offset-s1">${parseInt(this.getNetCash()).toLocaleString()}</div>
                  </div>
                </div>
                <div className="row blue-grey lighten-3 text-bold"> 
                  <div className="col s7">Cash yield</div> 
                  <div className="col s4 offset-s1">{this.getCashYield().toLocaleString(undefined,{style: 'percent', maximumFractionDigits: 2})}</div>
                </div>
            </div>

            <div className="output">
                <div className="switch row">
                  <label>
                    <h6 className="deep-purple-text text-darken-4 col s9">Equity</h6> 
                    <input type="checkbox" defaultChecked data-target="equity-details-collapsible" onChange={this.toggleDetails}/>
                    <span className="lever"></span>
                  </label>
                </div>
                <div id="equity-details-collapsible">
                  <div className="row blue-grey lighten-5"> 
                    <div className="col s7">Average principal paid down per year</div> 
                    <div className="col s4 offset-s1">${parseInt(this.getAverageEquityIncrease()).toLocaleString()} <i className="material-icons tooltipped" data-position="top" data-tooltip="This average is calculated over the first 7-year period of ownership">info</i></div>
                  </div>
                </div>
                <div className="row blue-grey lighten-3 text-bold"> 
                  <div className="col s7">Equity yield</div> 
                  <div className="col s4 offset-s1">{this.getEquityYield().toLocaleString(undefined,{style: 'percent', maximumFractionDigits: 2})} <i className="material-icons tooltipped" data-position="top" data-tooltip="Equity yield does not account for change in value of the underlying asset">info</i></div>
                </div>
            </div>
            
            <div className="output">
                <div className="switch row">
                  <label>
                    <h6 className="deep-purple-text text-darken-4 col s9">Taxes</h6> 
                    <input type="checkbox" defaultChecked data-target="taxes-details-collapsible" onChange={this.toggleDetails}/>
                    <span className="lever"></span>
                  </label>
                </div>
                <div id="taxes-details-collapsible">
                  <div className="row blue-grey lighten-5"> 
                    <div className="col s7">First year interest payments</div> 
                    <div className="col s4 offset-s1">${parseInt(this.getInterestPaymentForYear(0)).toLocaleString()}</div>
                  </div>
                  <div className="row blue-grey lighten-5"> 
                    <div className="col s7">Annual depreciation</div> 
                    <div className="col s4 offset-s1">${parseInt(this.getAnnualDepreciation()).toLocaleString()}</div>
                  </div>
                </div>
                <div className="row blue-grey lighten-3 text-bold"> 
                  <div className="col s7">Taxable income</div> 
                  <div className="col s4 offset-s1">${parseInt(this.getTaxableIncomeForYear(0, .03)).toLocaleString()}</div>
                </div>
            </div>
          
            
 
          </div>
          <button id="add_investment" className="btn-large waves-effect waves-light red" onClick={this.addOrUpdateInvestmentEntity}>Save</button>

        </div>

        <ResponsiveContainer width="95%" height={400}>
          <ComposedChart stackOffset="sign" data={this.state.graph_data} margin={{ top: 30, right: 30, bottom: 30, left: 30 }}>
            <Bar name="Annual Income" dataKey="Cash" type="monotone" fill="#82ca9d" />
            <Line name="Lifetime Income" dataKey="LifetimeIncome" type="monotone" stroke="green" />
            <Line dataKey="Equity" type="monotone" stroke="#8884d8" />
            <Line name="Equity Value" dataKey="EquityValue" type="monotone" stroke="red" />
            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
            <XAxis dataKey="Year" label={{ value: "Year", fontWeight: 700, dy: 10}} />
            <YAxis label={{ value: "Amount ($)", fontWeight: 700, position: "insideLeft", dx: -30, dy: 20}} />
            <Tooltip formatter={(value) => new Intl.NumberFormat('en').format(value)} />
            <Legend layout="vertical" wrapperStyle={{top: 30, left: 100}}/>
          </ComposedChart>
        </ResponsiveContainer>

        <ResponsiveContainer width="95%" height={400}>
          <ComposedChart stackOffset="sign"  data={this.state.graph_data} margin={{ top: 30, right: 30, bottom: 30, left: 30 }}>
            <Bar name="Annual Income" dataKey="Cash" type="monotone" fill="#82ca9d" />
            <Bar name="Annual Debt Service" stackId="a" dataKey="DebtService" type="monotone" fill="blue" />
            <Bar name="Annual Expenses" stackId="a" dataKey="Expenses" type="monotone" fill="red" />
            <Bar name="Net Cash After Debt Service" dataKey="NetCash" type="monotone" fill="green" />
            <Bar name="Taxable Income" dataKey="TaxableIncome" type="monotone" fill="black" />
            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
            <XAxis dataKey="Year" label={{ value: "Year", fontWeight: 700, dy: 10}} />
            <YAxis label={{ value: "Amount ($)", fontWeight: 700, position: "insideLeft", dx: -30, dy: 20}} />
            <Tooltip formatter={(value) => new Intl.NumberFormat('en').format(value)} />
            <Legend layout="vertical" wrapperStyle={{top: 30, left: 100}}/>
          </ComposedChart>
        </ResponsiveContainer>

        <ResponsiveContainer width="95%" height={400}>
          <LineChart data={this.state.graph_data} margin={{ top: 30, right: 30, bottom: 30, left: 30 }}>
            <Line name="Percent Equity" dataKey="PercentEquity" type="monotone" stroke="#8884d8" />
            <Line name="Cash Yield" dataKey="CashYield" type="monotone" stroke="#82ca9d" />
            <Line name="Total Cash Yield" dataKey="TotalCashYield" type="monotone" stroke="green" />
            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
            <XAxis dataKey="Year" label={{ value: "Year", fontWeight: 700, dy: 10}} />
            <YAxis type="number" domain={[0, 100]} allowDataOverflow={true} label={{ value: "Percent (%)", fontWeight: 700, position: "insideLeft", dx: -30, dy: 20}} />
            <Tooltip formatter={(value) => new Intl.NumberFormat('en').format(value)} />
            <Legend layout="vertical" wrapperStyle={{top: 30, left: 100}} />
          </LineChart>
        </ResponsiveContainer>
        

      </div>

      </span>
    );
  }
  
}

export default InvestmentEvaluator;