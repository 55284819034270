import React, {useEffect} from 'react';

const DealFlow = () => {

  //const API_ENDPOINT = process.env.REACT_APP_SERVER_HOST || 'http://localhost:9090'

  useEffect(() => {

    console.log("component loads")

  }, []);
  



  return (
    <div>
        Identify Property (ongoing)
        -OM
        -Rent roll
        -Property details / location / size
        -Tenants
        -Financial statement
        -Preliminary title report (~$200) [typically mortgage and some mechanics liens, which will be cleared in LOI]

        -Letter of intent (a few days)
        --We will buy property for this price, contingent on getting financing for such and such rate and such and such terms
        ---Tenant estoppels. Letters from tenant that verify the conditions of tenant lease.
        ---Right to speak to tenants
        --LOI also sent to lenders and/or loan brokers to see who is interested in financing the deal
  
        -If seller accepts LOI, and everything pans out, create formal offer 

        -Acceptance of offer
        --Open Escrow within 48 hours
        ---Typically 25k to 50k of earnest money.
        ---Buyer 
        --45 days of due diligence
        ---30 days to validate the financials. Counted from the day the information is received
        ---After 45 days passes, the earnest money goes hard, and you have 60 days to close the loan
        --60 days after for the bank to provide and close the loan. "No later than" closing dates

        Identify banks/lender(s)
        -Speak to a loan broker
        -Understand what lender requires


        

        Things we need for the bank
        -Phase 1 environmental. Result of a document search. Provided by listing agent.
        -If Phase 1 reveals anything that could be toxic, you reach out to lender, and find out what they want
        -For dry cleaner, gas station, etc. You need a phase 2.
        -Phase 2 is by request of the lender.
        -Verified evidence of rent being collected/deposited. Validation of rent roll.


        Rent roll
        Cap rate analysis 
        Void analysis
        Financing

        -Due diligence
        --Inspection
        --Permits
        --HVAC check
        --Roof check


        Title search / Title insurance
        -Riparian rights
        -Entitlements
        -Clouds on title
        -

        Purchase Agreement
        Sales Agreement

        Subscription Agreement
        Operating Agreement


    </div>
  );
};

export default DealFlow;
